import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BankStatement, BankStatementUploadStatus } from '../Models/BankStatement';
import { Record } from '../Models/Record';

// eslint-disable-next-line
const _sampleBankStatements = [
  { fileName: 'test1.pdf', progress: 'Done' },
  { fileName: 'test2.pdf', progress: 'Uploading' },
  { fileName: 'test3.pdf', progress: 'Done' },
  { fileName: 'test1.pdf', progress: 'Done' },
  { fileName: 'test2.pdf', progress: 'Error', errorMessage: 'Password ผิดจ้า' },
];

const _findStatement = (state: { bankStatementList: BankStatement[] }, fileName: string) =>
  state.bankStatementList.find((b) => b.fileName === fileName);
const _findStatementById = (state: { bankStatementList: BankStatement[] }, id: string) =>
  state.bankStatementList.find((b) => b.id === id);

const uploadBankStatement = createAsyncThunk(
  'bankStatementList/uploadBankStatement',
  async (params: { fileName: string }, { getState }) => {
    const rootState = getState() as any;
    const state = rootState.bankStatementSlice;
    const recordId = rootState.myRecordsSlice.activeRecord?.recordId;
    const clientId = rootState.myRecordsSlice.activeRecord?.clientId;

    if (recordId == null || clientId == null) {
      // console.log(`null record ID or user ID, can't upload statements`);
      return;
    }

    const bs = _findStatement(state, params.fileName);
    if (bs == null) {
      // console.log(`cant find ${params.fileName}`);
      return;
    }

    const _neverBeenUploaded = (bs: BankStatement) => bs.progress === 'Initial' || bs.s3path == null;

    if (
      ['Initial', 'Uploading'].includes(bs.progress) &&
      (!bs.needPassword || (bs.password !== '' && bs.password != null))
    ) {
      if (_neverBeenUploaded(bs)) {
        const reader = new FileReader();
        reader.readAsDataURL(bs.file as File);

        const b64content = await new Promise((resolve, reject) => {
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

        const res = await axios.post(`/api/internal/bank-statement/${clientId}/${recordId}`, {
          recordId: recordId,
          fileName: bs.fileName,
          password: bs.password,
          content: b64content,
        });
        return res.data.data;
      } else {
        await axios.post(`/api/internal/bank-statement/${clientId}/${recordId}/set-password`, {
          fileName: bs.fileName,
          password: bs.password,
        });
      }
    }
  }
);

export const removeBankStatement = createAsyncThunk(
  'bankStatementList/removeBankStatement',
  async (params: { fileName: string; clientId: string; recordId: string }) => {
    const { fileName, clientId, recordId } = params;
    try {
      await axios.delete(`/api/internal/bank-statement/${clientId}/${recordId}/${fileName}`);
      return fileName;
    } catch (err) {}
  }
);

// const rmvBankStatement = createAsyncThunk('')

const initialState = {
  recordName: '',
  recordId: '',
  status: 'Draft',
  bankStatementList: [] as BankStatement[],
};

const bankStatementSlice = createSlice({
  name: 'bankStatementList',
  initialState,
  reducers: {
    initializeWith(state, action: { payload: Record }) {
      state.recordId = action.payload.recordId as string;
      state.recordName = action.payload.name;
      state.status = action.payload.status;
      state.bankStatementList = action.payload.bankStatementList.map(_mapPayloadToBankStatement);
    },

    addOrModifyBankStatement(state, { payload }: { payload: Partial<BankStatement> }) {
      const bankStatement = {
        id: payload.id ?? uuid(),
        fileName: payload.file?.name,
        password: payload.password,
        progress: payload.progress ?? 'Initial',
        file: payload.file,
        needPassword: payload.needPassword,
      } as BankStatement;

      state.bankStatementList = state.bankStatementList.filter((b) => b.fileName !== bankStatement.fileName);
      state.bankStatementList.push(bankStatement);
    },

    clearBankStatement(state: any, action: {}) {
      state = initialState;
    },

    setRecordName(state, action: { payload: string }) {
      state.recordName = action.payload;
    },

    setRecordId(state, action: { payload: string }) {
      state.recordId = action.payload;
    },

    // removeBankStatement(state, action: { payload: BankStatement }) {
    //   state.bankStatementList = state.bankStatementList.filter((b) => b.fileName !== action.payload.fileName);
    // },

    setStatementPassword(state, action: { payload: { statementId: string; password: string; s3path?: string } }) {
      const statement = _findStatementById(state, action.payload.statementId);
      if (statement != null) {
        statement.password = action.payload.password;
        statement.progress = action.payload.s3path ? 'Done' : 'Initial';
        statement.errorMessage = '';
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('myRecord/setActiveRecord', (state, action: any) => {
        bankStatementSlice.caseReducers.initializeWith(state, { payload: action.payload });
      })
      .addCase('myRecord/clearActiveRecord', (state, action) => {
        state.bankStatementList = [];
      })
      .addCase('myRecord/addMyRecord', (state, action) => {
        state.bankStatementList = [];
      })
      .addCase(uploadBankStatement.pending, (state, action) => {
        const statement = _findStatement(state, action.meta.arg.fileName);
        if (statement == null) throw Error('ASSERT: Statement not found');
        statement.progress = 'Uploading';
      })

      .addCase(uploadBankStatement.fulfilled, (state, action) => {
        const statement = _findStatement(state, action.meta.arg.fileName);
        if (statement == null) throw Error('ASSERT: statement ID not found after finished uploading.');
        statement.progress = 'Done';
        statement.s3path = action.payload;
      })

      .addCase(uploadBankStatement.rejected, (state, action) => {
        const statement = _findStatement(state, action.meta.arg.fileName);
        if (statement == null) throw Error('ASSERT: Invalid Statement File');
        statement.progress = 'Error';
        statement.errorMessage = action.error.message ?? 'Unidentified Error';
      })

      .addCase(removeBankStatement.fulfilled, (state, action) => {
        state.bankStatementList = state.bankStatementList.filter((b) => b.fileName !== action.payload);
      });
  },
});

function _mapPayloadToBankStatement(bs: any): BankStatement {
  return {
    id: bs.id,
    fileName: bs.fileName,
    progress: __calculateProgress(bs),
    password: bs.pdfPassword ?? bs.password,
    needPassword: bs.needPassword ?? bs.errorMessage === 'INVALID_PASSWORD',
    errorMessage: bs.errorMessage,
    s3path: bs.s3path,
  };
}

function __calculateProgress(bs: any): BankStatementUploadStatus {
  if (bs.errorMessage) return 'Error';
  if (bs.s3path) return 'Done';
  return bs.progress ?? 'Initial';
}

export default bankStatementSlice.reducer;
export const {
  // removeBankStatement,
  initializeWith,
  clearBankStatement,
  setStatementPassword,
  addOrModifyBankStatement,
} = bankStatementSlice.actions;
export { uploadBankStatement };
