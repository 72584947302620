import { Input, Image, CloseButton } from '@mantine/core';

export function SearchInput({ keyword, setKeyword }: { keyword: string; setKeyword: (keyword: string) => void }) {
  return (
    <Input
      placeholder="ค้นหาผู้ใช้งาน"
      w="50%"
      fw="300"
      leftSection={<Image src="/assets/search.svg" w="20px" h="20px" />}
      rightSectionPointerEvents="all"
      rightSection={
        <CloseButton
          aria-label="Clear input"
          onClick={() => setKeyword('')}
          style={{ display: keyword ? undefined : 'none' }}
        />
      }
      value={keyword}
      onChange={(e) => setKeyword(e.target.value)}
    />
  );
}
