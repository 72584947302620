import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogBox } from '../CommonWidget/DialogBox';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../Store';
import { StatusBadge } from './StatusBadge';
import styled from 'styled-components';
import { Record } from '../Models/Record';
import { Text } from '@mantine/core';

const RecordEditor = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
const EditButton = styled(FontAwesomeIcon)`
  color: #9c9cab;
  cursor: pointer;
  @media print {
    display: none;
  }
`;

export function RecordHeader({ record, showStatus }: { record: Record | null; showStatus?: boolean }) {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  if (!record) return <></>;

  const _editRecord = () => {
    setShowEditDialog(true);
  };

  const _handleEditRecord = (button: string, input: string) => {
    // if (button === 'Confirm') {
    //   if (record.recordId != null)
    //     dispatch(renameRecord({ recordId: record.recordId, clientId: record.clientId, newName: input }));
    //   setShowEditDialog(false);
    // } else {
    //   setShowEditDialog(false);
    // }
    return false;
  };

  return (
    <RecordEditor>
      <Text size="lg" c="#26262C" fz="16px">
        {record?.name ?? 'No name'}
      </Text>
      {/* <EditButton className="edit-button" icon="pencil" onClick={_editRecord} /> */}
      {showStatus && <StatusBadge status={record?.status} />}

      <DialogBox
        show={showEditDialog}
        header="Edit record name"
        message="Enter new record name"
        buttons={['Cancel', 'Confirm']}
        input="New record name"
        initialValue={record?.name}
        onChanged={(show) => setShowEditDialog(show)}
        onCanceled={() => {
          setShowEditDialog(false);
          return true;
        }}
        onButtonPressed={_handleEditRecord}
      />
    </RecordEditor>
  );
}
