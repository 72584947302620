import { Provider } from 'react-redux';
import './App.css';
import '../font-awesome-loader';
import { store, persistor } from '../Store';
import { Router } from '../Views/Router';
import axios from 'axios';
import { createTheme, MantineProvider, PasswordInput, Table, Tabs, Text, TextInput } from '@mantine/core';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import '@mantine/notifications/styles.css';
import SessionValidator from '../common/validator/SessionValidator';
import { RolePermissionProvider } from '../context/role-context';
import { ModalsProvider } from '@mantine/modals';

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_BASEURL ?? '/';

  const defaultTextColor = '#5E5E6E';
  const theme = createTheme({
    colors: {
      purple: [
        '#f3f0fa',
        '#e3ddee',
        '#c6b6df',
        '#a78fd0',
        '#8c6cc3',
        '#7c56bb',
        '#4D2E83',
        '#633da2',
        '#583591',
        '#4b2d80',
      ],
    },
    primaryColor: 'purple',
    fontSizes: {
      md: '14px',
      lg: '16px',
      xl: '18px',
    },
    defaultRadius: 'md',
    headings: {
      textWrap: 'nowrap',
      sizes: {
        h3: {
          fontSize: '18px',
          fontWeight: '500',
          lineHeight: '22px',
        },
        h2: {
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '22px',
        },
      },
    },
    components: {
      Text: Text.extend({
        defaultProps: {
          fw: 300,
          c: defaultTextColor,
          fz: '16px',
        },
      }),
      TableTh: Table.Th.extend({
        defaultProps: {
          fw: 500,
          fz: 'lg',
          c: defaultTextColor,
        },
      }),
      TableTd: Table.Td.extend({
        defaultProps: {
          fw: 300,
          fz: 'md',
          c: defaultTextColor,
        },
      }),
      Tabs: Tabs.extend({
        defaultProps: {
          c: defaultTextColor,
        },
      }),
      TextInput: TextInput.extend({
        styles: {
          label: {
            fontWeight: 300,
            fontSize: '16px',
            marginBottom: '8px',
            color: '#5E5E6E',
            textWrap: 'nowrap',
          },
        },
        defaultProps: {
          fw: 300,
        },
      }),
      PasswordInput: PasswordInput.extend({
        styles: {
          label: {
            fontWeight: 300,
            fontSize: '16px',
            marginBottom: '8px',
            color: '#5E5E6E',
            textWrap: 'nowrap',
          },
        },
        defaultProps: {
          fw: 300,
        },
      }),
    },
    fontFamily:
      'Kanit,Avenir, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  });

  return (
    <MantineProvider theme={theme}>
      <ModalsProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <RolePermissionProvider>
              <div className="App">
                <BrowserRouter>
                  <SessionValidator>
                    <Router />
                  </SessionValidator>
                </BrowserRouter>
              </div>
            </RolePermissionProvider>
          </PersistGate>
        </Provider>
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;
