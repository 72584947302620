import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faChevronLeft,
    faX,
    faSpinner,
    faCheck,
    faPencil,
    faTrash,
    faEye,
    faEyeSlash,
    faAngleRight,
    faChevronRight,
    faAnglesLeft,
    faAnglesRight,
    faAngleLeft,
    faShare,
    faTimes,
    faClone,
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faChevronLeft,
    faChevronRight,
    faAnglesLeft,
    faAnglesRight,
    faX,
    faSpinner,
    faCheck,
    faPencil,
    faTrash,
    faEye,
    faEyeSlash,
    faAngleRight,
    faAngleLeft,
    faShare,
    faPencil,
    faTimes,
    faClone,
)
