import axios from 'axios';
import { APIResponse } from './api';

export const getPresignUrl = async (s3path?: string): Promise<[false, string] | [true, undefined]> => {
  try {
    const res = await axios.post<APIResponse<string>>(`/api/internal/bank-statement/presign-url/download`, {
      s3path,
    });

    return [false, res.data.data];
  } catch (err) {
    return [true, undefined];
  }
};
