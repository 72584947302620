import { Popover, Button, Text } from '@mantine/core';
import { IconLink } from '@tabler/icons-react';
import { useState } from 'react';
import { refreshToken } from '../../../../../api/customer';
import { useTenantRedirectURL } from '../../../../../hooks/user';

export default function CopyLinkButton({ customerId, clientId }: { customerId: string; clientId: string }) {
  const [showCopiedMsg, setShowCopiedMsg] = useState(false);
  const baseURL = useTenantRedirectURL();

  const _copyLinkToClipboard = async () => {
    try {
      const token = await refreshToken(customerId, clientId);

      if (!token) return;
      const shortToken = token.substring(0, 16);
      const link = `${baseURL}/${clientId}/validate/?token=${shortToken}`;
      await navigator.clipboard.writeText(link);
      setShowCopiedMsg(true);
      setTimeout(() => setShowCopiedMsg(false), 3000);
    } catch (err) {
      console.error('Error copying to clipboard:', err);
    }
  };

  return (
    <Popover withArrow shadow="sm" closeOnClickOutside opened={showCopiedMsg} onChange={setShowCopiedMsg}>
      <Popover.Target>
        <Button
          leftSection={<IconLink size="1.5em" />}
          variant="outline"
          onClick={() => _copyLinkToClipboard()}
          style={{ border: '2px solid #DEDEE3' }}
        >
          คัดลอกลิงก์อัปโหลด
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Text>คัดลอกแล้ว</Text>
      </Popover.Dropdown>
    </Popover>
  );
}
