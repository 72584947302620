import { Button, Group, Space, Stack, Title } from '@mantine/core';
import { PageWrapper } from '../../Views/PageWrapper';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useClientId, useTenantAlias, useUsers } from '../../hooks/user';
import { useDebouncedValue } from '@mantine/hooks';
import { SearchInput } from './components/SearchInput';
import { UserContent } from './components/UserContent';

export function UserManagementPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState<string>('');
  const [debouncedKeyword] = useDebouncedValue(keyword, 500);
  const clientId = useClientId();
  const page = searchParams.get('page') || '1';
  const users = useUsers(clientId, page, debouncedKeyword);
  const alias = useTenantAlias();

  useEffect(() => {
    setSearchParams({ page: '1' });
  }, [debouncedKeyword]);

  const handlePageChange = (pageNumber: number) => {
    setSearchParams({ page: pageNumber.toString() });
  };

  const handleCreateUser = () => {
    navigate(`/${alias}/users/new`);
  };

  if (!users) return null;

  return (
    <PageWrapper>
      <Stack h="100%">
        <Title order={3} c="#4D2E83">
          จัดการผู้ใช้งาน
        </Title>
        <Group justify="space-between">
          <SearchInput keyword={keyword} setKeyword={setKeyword} />
          <Button onClick={handleCreateUser}>เพิ่มผู้ใช้งาน</Button>
        </Group>
        <UserContent users={users} page={page} handlePageChange={handlePageChange} />
        <Space h="lg" />
      </Stack>
    </PageWrapper>
  );
}
