import { useDispatch } from 'react-redux';

import { useState } from 'react';
import { logout } from '../Features/UserSlice';
import { Flex, Image, Button, ActionIcon, Menu, Modal, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { persistor } from '../Store';

export function TopBar() {
  const dispatch = useDispatch();
  const [showCreateNewRecordDialog, setShowCreateNewRecordDialog] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const _handleSignout = async () => {
    await persistor.purge();
    dispatch(logout({}));
    navigate('/login');
  };

  return (
    <Group grow justify="space-between" align="center" h="100%">
      <Group w="260" align="center">
        <Image src="/assets/thunderpass-portal-logo.svg" fit="contain" w="265" />
      </Group>
      <Group justify="flex-end" align="center" mx="1rem">
        <Menu>
          <Menu.Target>
            <ActionIcon variant="transparent">
              <Image src="/assets/avatar-placeholder.svg" alt="avatar" width="24px" height="24px" />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={open}>Sign out</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      <Modal opened={opened} onClose={close} title="Sign Out" size="lg" centered>
        Are you sure you want to sign out?
        <Flex mt="lg">
          <Button mr="sm" onClick={close} variant="outline">
            Back
          </Button>
          <Button onClick={() => _handleSignout()}>Sign out</Button>
        </Flex>
      </Modal>
    </Group>
  );
}
