import { Table, Image, Text, Box, Flex, ActionIcon } from '@mantine/core';
import { User } from '../../../hooks/user';

export function UserTable({
  data,
  handleDelete,
  handleRowClick,
}: {
  data: User[];
  handleDelete: (user: User) => void;
  handleRowClick: (userId: string) => void;
}) {
  return (
    <Table withRowBorders highlightOnHover verticalSpacing="sm" horizontalSpacing="md" w="100%">
      <Table.Thead>
        <Table.Tr style={{ border: 0 }}>
          <Table.Th fw={300}>ชื่อผู้ใช้งาน</Table.Th>
          <Table.Th fw={300}>อีเมล</Table.Th>
          <Table.Th fw={300}>หน้าที่</Table.Th>
          <Table.Th fw={300}></Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {!data || data.length === 0 ? (
          <Table.Tr>
            <Table.Td key={0} colSpan={100} align="center">
              <Image src="/assets/not_found.svg" w="175px" h="135px" mt="149px" />
              <Text size="lg" mt="8px">
                ไม่พบข้อมูล
              </Text>
            </Table.Td>
          </Table.Tr>
        ) : (
          data.map((user, index) => (
            <Table.Tr key={`${user.userId} + ${index}`} onClick={() => handleRowClick(user.userId)}>
              <Table.Td fw="500">{user.firstname ? `${user.firstname} ${user.lastname}` : '-'}</Table.Td>
              <Table.Td>{user.userId}</Table.Td>
              <Table.Td>{user.roles?.[0]}</Table.Td>
              <Table.Td>
                <Flex ml="auto" justify="flex-end">
                  <ActionIcon
                    variant="white"
                    w="16px"
                    h="16px"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(user);
                    }}
                  >
                    <Image src={'/assets/trash.svg'} />
                  </ActionIcon>
                </Flex>
              </Table.Td>
            </Table.Tr>
          ))
        )}
      </Table.Tbody>
    </Table>
  );
}
