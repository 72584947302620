import { useSelector } from 'react-redux';
import { RootState } from '../Store';
import { useEffect, useState } from 'react';
import axios from 'axios';

export type User = {
  userId: string;
  clientId: string;
  firstname: string;
  lastname: string;
  roles: string[];
};

export type PaginationResponse<T> = {
  data: T;
  total: number;
  totalPage: number;
  currentPage: number;
};

export type ApiResponse<T> = {
  status: string;
  data: T;
};

export const useClientId = () => {
  const user = useSelector((state: RootState) => state.userSlice.activeUser);
  return user?.tenant.clientId;
};

export const useTenantAlias = () => {
  const user = useSelector((state: RootState) => state.userSlice.activeUser);
  return user?.tenant.alias;
};

export const useTenantRedirectURL = () => {
  const user = useSelector((state: RootState) => state.userSlice.activeUser);
  return user?.tenant.redirectURL;
};

export const useUsers = (clientId?: string, page?: string, keyword?: string) => {
  const [users, setUsers] = useState<PaginationResponse<User[]> | undefined>(undefined);

  useEffect(() => {
    const loadUsers = async () => {
      const response = await axios.get<ApiResponse<PaginationResponse<User[]>>>('/api/internal/user', {
        params: { clientId, page, keyword },
      });
      setUsers(response.data.data);
    };
    loadUsers();
  }, [clientId, page, keyword]);

  return users;
};
