import { Box, Tabs, Group } from '@mantine/core';
import { PageWrapper } from '../../../../../Views/PageWrapper';
import { useClientId, useTenantAlias } from '../../../../../hooks/user';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Store';

import { Record } from '../../../../../Models/Record';
import { useEffect, useState } from 'react';
import { getRecord } from '../../../../../Features/recordSlice';
import SummarySection from './components/SummarySection';
import Overview from './components/OverviewSection';
// @ts-ignore
import classes from './page.module.css';
import StatementSection from './components/StatementSection';
import RecordHeaderField from './components/RecordHeaderField';
import { AppBreadCrumbs } from '../../../../../common/components/BreadCrumbs';

export default function ReportSummaryPage() {
  const [record, setRecord] = useState<Record | null>(null);
  const customer = useSelector((state: RootState) => state.customerSlice.activeCustomer);
  const clientId = useClientId();
  const alias = useTenantAlias();
  const { customerId, recordId } = useParams();

  useEffect(() => {
    const loadData = async () => {
      const rec = await getRecord({ clientId, recordId });
      setRecord(rec);
    };
    loadData();
  }, []);

  const result = record?.result;
  const customerName = `${customer.firstname} ${customer.lastname}`;
  const breadCrumbItems = [
    {
      label: 'ข้อมูลลูกค้า',
      href: `/${alias}/customer`,
    },
    {
      label: customerName,
      href: `/${alias}/customer/${customerId}/data`,
    },
  ];

  return (
    <PageWrapper>
      <Group mb="22px">
        <AppBreadCrumbs items={breadCrumbItems} />
      </Group>

      <RecordHeaderField record={record} />
      <Overview data={result?.['Overview']}></Overview>

      <Box mt="24px">
        <Tabs defaultValue="summary" classNames={classes}>
          <Tabs.List grow justify="space-between" fw={500}>
            <Tabs.Tab value="summary">Summary</Tabs.Tab>
            <Tabs.Tab value="statements">Statements</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="summary">{record && <SummarySection record={record} customer={customer} />}</Tabs.Panel>

          <Tabs.Panel value="statements">
            {record && <StatementSection record={record} customer={customer} />}
          </Tabs.Panel>
        </Tabs>
      </Box>
      <Box pb="100px" />
    </PageWrapper>
  );
}
