import {
  ActionIcon,
  Button,
  Container,
  Flex,
  Group,
  Stack,
  TextInput,
  Title,
  Image,
  Text,
  Modal,
  LoadingOverlay,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { forgetPassword, verifyEmail } from '../../api/user';
import { useState } from 'react';

export default function ForgetPasswordPage() {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const form = useForm({
    initialValues: {
      email: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'อีเมลไม่ถูกต้อง'),
    },
  });

  const handleSubmit = async (values: typeof form.values) => {
    setLoading(true);
    const isValid = await checkEmailExist(values.email);
    if (!isValid) {
      form.setFieldError('email', 'อีเมลไม่มีอยู่ในระบบ');
      setLoading(false);
      return;
    }
    await forgetPassword(values.email);
    setLoading(false);
    open();
  };

  const checkEmailExist = async (email: string) => {
    const [, valid] = await verifyEmail(email);
    return valid;
  };

  return (
    <Container fluid h="100vh" p="xl">
      <LoadingOverlay visible={isLoading} zIndex={1000} />
      <Stack p="16px">
        <Group mt="16px" w="fit-content" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
          <ActionIcon
            variant="white"
            w="32px"
            h="32px"
            style={{ borderRadius: '32px', boxShadow: '0px 2px 4px 0px rgba(18, 18, 64, 0.08' }}
          >
            <Image src="/assets/back_left.svg" />
          </ActionIcon>
          <Text>กลับไปหน้าเข้าสู่ระบบ</Text>
        </Group>
        <section style={{ marginTop: '15%' }}>
          <Stack w="28%" m="auto">
            <Title ta="center" fz="24px" mb="24px" c="#26262C">
              ใส่อีเมลเพื่อตั้งรหัสผ่านใหม่
            </Title>
            <form onSubmit={form.onSubmit(handleSubmit)} style={{ display: 'flex', flexDirection: 'column' }}>
              <TextInput size="lg" mb="24px" label="อีเมล" {...form.getInputProps('email')} />
              <Button size="lg" type="submit">
                ส่งอีเมล
              </Button>
            </form>
          </Stack>
        </section>
      </Stack>
      <Modal opened={opened} withCloseButton={false} onClose={close} centered size="xs">
        <Modal.Header>
          <Image src="/assets/check-circle.svg" ml="auto" mr="auto" />
        </Modal.Header>
        <Modal.Body>
          <Stack justify="center">
            <Text fw={500} fz="16px" c={'#26262C'} ta="center">
              ส่งอีเมลเรียบร้อย
            </Text>
            <Button size="lg" onClick={() => navigate('/login')}>
              ตกลง
            </Button>
          </Stack>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
