/**
 * return unique groups of items with $group property.
 * 
 * for example:
 * const items = [{ $group: 'A', name: 'a' }, { $group: 'B', name: 'b' }, { $group: 'A', name: 'c' }]
 * group(items) // ['A', 'B']
 * 
 * The sort order is based on the first appearance of the group.
 * 
 * @param items 
 * @returns 
 */
export function group(items: {$group: string}[]): string[] {
    return Array.from(items.reduce((set, item) => {
        set.add(item?.$group ?? 'All')
        return set
    }, new Set<string>()))
}