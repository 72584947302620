import { createContext, useContext } from 'react';
import { RootState } from '../Store';
import { useSelector } from 'react-redux';
import { Role } from '../Models/UserInfo';

export type RolePermissionContextValue = { [key in Role]?: boolean };
const RolePermissionContext = createContext<RolePermissionContextValue>({});
RolePermissionContext.displayName = 'RolePermissionContext';

const RolePermissionProvider = (props: any) => {
  const user = useSelector((state: RootState) => state.userSlice.activeUser);
  return <RolePermissionContext.Provider value={toContextValue(user?.roles)} {...props} />;
};

const useRole = (): RolePermissionContextValue => {
  const role = useContext(RolePermissionContext);
  return role;
};

const toContextValue = (roles?: Role[]) => {
  if (!roles) return {};
  const allow: { [key in Role]?: boolean } = {};
  roles.forEach((role) => {
    allow[role] = true;
  });
  return allow;
};

export { RolePermissionProvider, useRole };
