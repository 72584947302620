import { NavLink, Image, Divider, Stack, Box } from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { useClientId, useTenantAlias } from '../hooks/user';
import { RoleGate } from '../common/permission/RoleGate';
import { Role } from '../Models/UserInfo';

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const alias = useTenantAlias();

  return (
    <Stack gap="4px" w="90%" m="24px auto">
      <NavLink
        label="ข้อมูลลูกค้า"
        leftSection={<Image src="/assets/my-record.svg" />}
        onClick={() => navigate(`/${alias}/customer`)}
        variant="light"
        active={currentPath.startsWith(`/${alias}/customer`)}
        style={{
          borderRadius: '8px',
        }}
      />

      <RoleGate scopes={[Role.SuperAdmin]}>
        <NavLink
          label="จัดการผู้ใช้งาน"
          leftSection={<Image src="/assets/icon-users.svg" />}
          onClick={() => navigate(`/${alias}/users`)}
          variant="light"
          active={currentPath.startsWith(`/${alias}/users`)}
          style={{
            borderRadius: '8px',
          }}
        />
      </RoleGate>
      {/* <Divider mt="24px" mb="24px" />
      <NavLink
        label="โปรไฟล์ส่วนตัว"
        leftSection={<Image src="/assets/icon-account_circle.svg" />}
        // onClick={() => navigate(`/${alias}/profile`)}
        variant="light"
        // active={currentPath.startsWith(`/${alias}/profile`)}
        style={{
          borderRadius: '8px',
        }}
      />
      <NavLink
        label="ฟีเจอร์ทั้งหมด"
        leftSection={<Image src="/assets/icon-category.svg" />}
        // onClick={() => navigate(`/${alias}/features`)}
        variant="light"
        // active={currentPath === `/${alias}/features`}
        style={{
          borderRadius: '8px',
        }}
      /> */}
    </Stack>
  );
};

export default SideBar;
