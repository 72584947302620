import { BreadCrump } from '../CommonWidget/Breadcrump';
import { Pagination } from '../CommonWidget/Pagination';
import { useEffect, useState } from 'react';
import { ItemSelector } from '../CommonWidget/ItemSelector';
import { RecordHeader } from '../Widgets/RecordHeader';
import { PageContent } from '../CommonWidget/PageContent';
import styled from 'styled-components';
import { Table } from '../CommonWidget/Table';
import { group } from '../Utils/DataGroupper';
import { PageWrapper } from './PageWrapper';
import { useLocation } from 'react-router-dom';

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
/**
 * onBack is optional, in standard case it is unset; which the back button will bring the user back to record page.
 * The onBack is set when ReportDetailPage is in the editor window.
 *
 * @param props
 * @returns
 */
export function ReportDetailPage(props: { selected: number; items: any[]; record: any; onBack?: () => void }) {
  const { state } = useLocation();
  const [selectedGroup, setSelectedGroup] = useState((props.selected || state.selected) ?? 0);
  const [currentPage, setCurrentPage] = useState(0);

  const perPage = 12;
  const items = props.items || state.items;
  const record = props.record || state.record;
  const groups = group(items);

  const _onGroupSelected = (_selGroup: number) => {
    setSelectedGroup(_selGroup);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [selectedGroup]);

  const _removeControlFields = (t: any) => Object.fromEntries(Object.entries(t).filter((e) => !e[0].startsWith('$')));
  const _selectedGroup = (item: { $group: string }) =>
    item.$group == null ? groups[selectedGroup] === 'All' : item.$group === groups[selectedGroup];
  const _currentPage = (_: any, i: number) => i >= perPage * currentPage && i < perPage * (currentPage + 1);

  const BackToEditorButton = styled.div`
    cursor: pointer;
  `;

  return (
    <PageWrapper>
      <PageContent>
        {!props.onBack ? (
          <BreadCrump items={[{ label: 'Back to Summary', link: 'Report', props: { record: record } }]} />
        ) : (
          <BackToEditorButton onClick={props.onBack}>&lt; Back to Editor</BackToEditorButton>
        )}
        <TitleRow>
          <RecordHeader record={record} />
          <ItemSelector selectedIndex={selectedGroup} items={groups} onItemSelected={_onGroupSelected} />
        </TitleRow>
        <Table items={items.filter(_selectedGroup).filter(_currentPage).map(_removeControlFields)} />
        <Pagination
          currentPage={currentPage}
          numItems={items.filter(_selectedGroup).length}
          perPage={perPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </PageContent>
    </PageWrapper>
  );
}
