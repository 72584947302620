import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserInfo } from '../Models/UserInfo';
import axios from 'axios';
import { PURGE } from 'redux-persist';

const login = createAsyncThunk('user/login', async (params: { userId: string; password: string }, { getState }) => {
  const ret = await axios.post(`/api/internal/auth/login`, {
    userId: params.userId,
    password: params.password,
  });

  return ret.data;
});

export interface UserState {
  activeUser: UserInfo | null;
  error: string | null;
}

const initialState: UserState = {
  activeUser: _initializeFromSessionStorage(),
  error: null,
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState: initialState,
  reducers: {
    setActiveUser: (state, action: { payload: UserInfo }) => {
      state.activeUser = action.payload;
      _recordToSessionStorage(action.payload);
    },

    logout(state, action) {
      state.activeUser = null;
      _removeSessionStorage();
    },
  },

  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      try {
        const token = action.payload.accessToken?.split('.')[1];

        if (!token) {
          throw new Error('Something went wrong. Please try again later.');
        }

        // Convert Base64 URL encoding to Base64
        const base64 = token.replace(/-/g, '+').replace(/_/g, '/');

        // Decode the Base64 string
        const data = atob(base64);
        const jsonData = JSON.parse(data ?? '{}');

        const user: UserInfo = {
          userId: jsonData.userId,
          username: jsonData.displayName,
          tenant: jsonData.tenant,
          roles: jsonData.roles,
          authToken: action.payload.accessToken,
        };

        axios.defaults.headers.common['Authorization'] = `Bearer ${user.authToken}`;

        // TODO verify signature

        userSlice.caseReducers.setActiveUser(state, { payload: user });
      } catch (e) {
        state.error = (e as Error).message;
      }
    });

    builder.addCase(login.rejected, (state, action) => {
      state.error = 'Login failed.';
      userSlice.caseReducers.logout(state, { payload: {}, type: 'user/logout' });
    });

    builder.addCase(PURGE, () => initialState);
  },
});

function _initializeFromSessionStorage(): UserInfo | null {
  const user = sessionStorage.getItem('user');

  if (user != null) {
    try {
      const userInfo = JSON.parse(user) as UserInfo;

      // TODO: Refactor this side-effect out
      axios.defaults.headers.common['Authorization'] = `Bearer ${userInfo.authToken}`;
      return userInfo;
    } catch (jsonParseError) {
      sessionStorage.removeItem('user');
      return null;
    }
  } else return null;
}

function _recordToSessionStorage(user: UserInfo) {
  sessionStorage.setItem('user', JSON.stringify(user));
}

function _removeSessionStorage() {
  sessionStorage.removeItem('user');
}

export default userSlice.reducer;
export const { setActiveUser, logout } = userSlice.actions;
export { login };
