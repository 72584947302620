import axios from 'axios';
import { APIResponse } from './api';
import { User } from '../hooks/user';

type CreateUserPayload = {
  email: string;
  firstname: string;
  lastname: string;
};

export const createUser = async (
  clientId: string,
  payload: CreateUserPayload
): Promise<[boolean, APIResponse<string> | undefined]> => {
  const { email, firstname, lastname } = payload;
  try {
    const res = await axios.post<APIResponse<string>>(`/api/internal/user/create`, {
      clientId,
      userId: email,
      firstname,
      lastname,
    });
    return [false, res.data];
  } catch (err) {
    return [true, undefined];
  }
};

export const deleteUser = async (user: User) => {
  try {
    const { clientId, userId } = user;
    const res = await axios.delete(`/api/internal/user/${clientId}/${userId}`);
    return [false, res.data];
  } catch (err) {
    return [true, undefined];
  }
};

export const getUser = async (clientId: string, userId: string): Promise<[false, User] | [true, undefined]> => {
  try {
    const res = await axios.get<APIResponse<User>>(`/api/internal/user/${clientId}/${userId}`);
    return [false, res.data.data];
  } catch (err) {
    return [true, undefined];
  }
};

export const verifyEmail = async (email: string): Promise<[false, boolean] | [true, false]> => {
  try {
    const res = await axios.post<APIResponse<boolean>>(`/api/internal/user/verify-email`, {
      email,
    });
    return [false, res.data.data];
  } catch (err) {
    return [true, false];
  }
};

export const verifyLink = async (token: string | null): Promise<[false, string] | [true, false]> => {
  if (!token) return [true, false];
  try {
    const res = await axios.post<APIResponse<string>>(`/api/internal/user/verify-link`, {
      token,
    });
    return [false, res.data.data];
  } catch (err) {
    return [true, false];
  }
};

export const forgetPassword = async (email: string): Promise<void> => {
  try {
    await axios.post<APIResponse<User>>(`/api/internal/user/forget-password`, {
      userId: email,
    });
  } catch (err) {
    return;
  }
};

export const resetPassword = async (email: string, password: string): Promise<void> => {
  try {
    await axios.post<APIResponse<User>>(`/api/internal/user/reset-password`, {
      email,
      password,
    });
  } catch (err) {
    return;
  }
};
