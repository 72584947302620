import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BankStatement } from '../Models/BankStatement';
import { useDispatch } from 'react-redux';
import { removeBankStatement } from '../Features/BankStatementListSlice';
import { useState } from 'react';

import { AppDispatch } from '../Store';
import { useClientId } from '../hooks/user';
import { ActionIcon, Box, Button, Group, Image, PasswordInput, Stack, Text } from '@mantine/core';

const errorCodeMap: Record<string, string> = {
  INVALID_PASSWORD: 'รหัสผ่านไม่ถูกต้อง',
  INVALID_FILE: 'ไฟล์นี้ไม่ถูกต้อง โปรดลบและอัปโหลดไฟล์ที่ถูกต้อง',
  // 'CANNOT_LOAD_FILE': "ไฟล์นี้ไม่ถูกต้อง โปรดลบและอัปโหลดไฟล์ที่ถูกต้อง",
};

export function BankStatementEntry({
  statement,
  needPassword,
  setPassword,
  handleDownload,
  recordId,
}: {
  statement: BankStatement;
  needPassword: boolean;
  setPassword: (p: string) => any;
  handleDownload: (statement: BankStatement) => Promise<void>;
  recordId: string;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const clientId = useClientId();
  const [value, setValue] = useState('');
  const [error, setError] = useState(statement.errorMessage);

  if (!clientId) return;

  const _onDelete = (fileName: string) => {
    dispatch(removeBankStatement({ fileName, clientId, recordId }));
  };

  return (
    <Box mb="16px">
      <Stack
        style={{
          border: error && error !== 'INVALID_PASSWORD' ? '1px solid #EC1E35' : '1px solid #DEDEE3',
          borderRadius: '8px',
        }}
        p="16px"
      >
        <Group>
          {statement.progress === 'Done' && (
            <FontAwesomeIcon className="action-button success" icon="check" color="#10B2A4" />
          )}
          {statement.progress === 'Uploading' && (
            <FontAwesomeIcon className="action-button" icon="spinner" spin pulse />
          )}
          <Image src="/assets/icon-doc.svg" alt="icon-doc" />
          <Text>{statement.fileName} </Text>
          <Group ml="auto">
            {statement.progress === 'Done' && (
              <ActionIcon variant="white" onClick={() => handleDownload(statement)}>
                <Image src={'/assets/file_download.svg'} />
              </ActionIcon>
            )}
            <ActionIcon variant="white" onClick={() => _onDelete(statement.fileName)}>
              <FontAwesomeIcon className="action-button" icon="x" color="#9C9CAB" />
            </ActionIcon>
          </Group>
        </Group>
        {needPassword && !statement.password && (
          <Group align="flex-start">
            <PasswordInput
              w="83%"
              error={errorCodeMap[error ? error : '']}
              onChange={(e) => setValue(e.currentTarget.value)}
              onFocus={() => setError(undefined)}
              styles={{
                error: {
                  fontSize: '16px',
                  fontWeight: '300',
                },
              }}
            />
            <Button w="15%" onClick={() => setPassword(value)} disabled={!value}>
              Confirm
            </Button>
          </Group>
        )}
      </Stack>
      {error && error !== 'INVALID_PASSWORD' && (
        <Text c="#FB2323" mt="8px">
          {errorCodeMap[error]}
        </Text>
      )}
    </Box>
  );
}
