import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Button } from './Button'
import { TextInput } from './TextInput'

const DialogBoxContainer = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`

const Backdrop = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(38, 38, 44, 0.40);
    
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(1.3px);
`

const DialogEnclosure = styled.div `
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const Dialog = styled.div`
    border-radius: 8px;
    background: var(--text-invert, #FFF);
    box-shadow: 0px 8px 16px 0px rgba(222, 222, 227, 0.40);
    padding: 24px;
    min-width: 466px;
    z-index: 1000;
`

const CloseButton = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    cursor: pointer;
`

const DialogContent = styled.div`
    row-gap: 18px;
    display: flex;
    flex-direction: column;
`

const DialogHeader = styled.div`
    & h2 {
        color: var(--brand-abacus-primary-red, var(--brand-abacus-primary-red, #EC1E35));
        font-family: Avenir;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        margin: 0;
        padding: 0;
    }
`

const DialogBody = styled.div`
    font-size: 16px;
    display: flex;
    flex-direction: column;

    & input {
        border-radius: 8px;
        border: 1px solid var(--stroke-default, #DEDEE3);
        background: var(--fill-card-default, #FFF);
        padding: 8px 12px;
    }
`

const DialogFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;

    & button { 
        flex-grow: 1;

        background-color: white;
        border-radius: 8px;
        border: 2px solid var(--stroke-default, #DEDEE3);
        background: var(--text-invert, #FFF);

        color: var(--brand-abacus-primary-red, var(--brand-abacus-primary-red, #EC1E35));
        font-family: Avenir;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px; /* 142.857% */

        &:last-of-type {
            border-radius: 8px;
            background: var(--brand-abacus-primary-red, #EC1E35);
            color: white;
        }
    }
`

export type ButtonProp = {
    title: string
    icon?: IconProp // fa icon
    secondary?: boolean // will display in secondary color/style
}

export function DialogBox(props: { 
    header?: string, 
    message: string, 
    buttons: (string|ButtonProp)[], 
    onButtonPressed?: (button: string, input: string) => boolean, 
    onCanceled?: () => boolean,
    onChanged?: (show: boolean) => void, // Fire when visibility status changed.
    show: boolean, 
    input?: string,
    initialValue?: string, // Initial value to initialize the input field
    stayOpenWhenClickOutside?: boolean, // default to false. Will trigger Cancel/close when clicked outside.
    }, // The input label. Result will be sent in an argument on onButtonPressed
    ) {
    const [inputValue, setInputValue] = useState('')
    const inputElement = useRef<HTMLInputElement>(null)

    useEffect(() => { 
        setInputValue(props.initialValue ?? '')
        if (inputElement != null) inputElement.current?.focus()
    }, [props.initialValue, props.show])

    const _handleClickOutside = (e: any) => {
        e.stopPropagation()
        return props.stayOpenWhenClickOutside ? null : props.onCanceled?.() ?? props.onChanged?.(false)
    }

    const _handleClose = (e: MouseEvent) => {
        e.stopPropagation()
        props.onCanceled?.()
        props.onChanged?.(false)
    }

    const _buttons: ButtonProp[] = props.buttons.map(b => typeof b === 'string' ? { title: b }: b)

    return <DialogBoxContainer hidden={!props.show}>
        <Backdrop/>
        <DialogEnclosure onClick={_handleClickOutside}>
            <Dialog onClick={(e) => e.stopPropagation()}>
                <CloseButton><FontAwesomeIcon className="icon" icon='times' onClick={_handleClose} /></CloseButton>
                <DialogContent>
                    { props.header && <DialogHeader> <h2>{props.header}</h2> </DialogHeader> }

                    <DialogBody>
                        <p>{props.message}</p>
                        { props.input && <TextInput ref={inputElement} 
                            value={inputValue} 
                            placeholder={props.input}
                            onInput={(value)=> { setInputValue(value.currentTarget.value) }}
                            onKeyDown={(e) => { 
                                if (e.key === 'Enter') {
                                    props.onButtonPressed?.(_buttons[0].title, inputValue)
                                    props.onChanged?.(false)
                                }
                            }} /> }
                    </DialogBody>

                    <DialogFooter>
                        {
                            _buttons.map(btn => {
                                return <Button key={btn.title} disabled={props.input ? inputValue.length===0 : false} onClick={()=>{ 
                                            props.onButtonPressed?.(btn.title as string, inputValue)
                                            props.onChanged?.(false)
                                        }}>
                                    {btn.icon ? <FontAwesomeIcon icon={btn.icon}/> : null }
                                    {btn.title}
                                </Button>
                            })
                        }
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </DialogEnclosure>
    </DialogBoxContainer>
}