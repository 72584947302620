/**
 * Apply a mask to a value based on a pattern identified.
 * 
 * see the defition of 'pattern' below more details about the available pattern.
 * 
 * @param value 
 * @param pattern A string containing the pattern to be applied to the value. The following characters are available:
 * - '9': Any digit or letter
 * - 'a': Any letter, convert to lower case if the input is a letter
 * - 'A': Any letter, convert to upper case if the input is a letter
 * - the masked char: Any character, if the masked flag is true, the resulting character at the position will be replaced by the maskChar
 * 
 * Known issue: there is currently no way to support the use of the maskChar and control chars as a literal character in the pattern. 
 * Future improvement: add a way to escape the maskChar in the pattern.
 * 
 * @param maskChar the character to be used as a mask. Default is 'x'
 * @param masked the flag to indicate if the resulting character at the position will be replaced by the maskChar. Default is true. 
 * If this flag is false, the resulting string will simply be 'formatted' with the pattern, but the maskChar will not be used.
 * @returns 
 */
export function mask(value: string, pattern: string = '999-999-9999', maskChar: string = 'x', masked: boolean = true): string {
    const _input = value.replace(/[^0-9A-Za-z]/g, '').split('')

    const result = pattern?.split('').map( (p, i) => {
        if (p === '9') {
            return _input.shift() ?? ' '
        } else if (p === 'a') {
            const c = _input.shift()
            return c?.toLowerCase() ?? ' '
        } else if (p === 'A') {
            const c = _input.shift()
            return c?.toUpperCase() ?? ' '
        } else if (p === maskChar){
            const c = _input.shift()
            return masked ? maskChar : c
        } else {
            return p
        }
    }) ?? ['']
    return result.join('')
}