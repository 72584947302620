import { Input, Button, Text, Image, Group } from '@mantine/core';
import { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { renameRecord } from '../../../../../../Features/MyRecordsSlice';
import { AppDispatch } from '../../../../../../Store';
import { Record } from '../../../../../../Models/Record';

export default function RecordHeaderField({ record }: { record: Record | null }) {
  const recordName = record?.name;
  const dispatch = useDispatch<AppDispatch>();
  const [isEditing, setIsEditing] = useState(false);
  const [editedRecordName, setEditedRecordName] = useState<string | undefined>(recordName);
  const { customerId } = useParams();

  useEffect(() => {
    if (recordName) {
      setEditedRecordName(recordName);
    }
  }, [recordName]);

  if (!record || !customerId) return <></>;

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    dispatch(
      renameRecord({
        recordId: record.recordId,
        clientId: record.clientId,
        newName: editedRecordName,
        customerId,
      })
    );
  };

  const handleCancelClick = () => {
    setEditedRecordName(recordName);
    setIsEditing(false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditedRecordName(event.target.value);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      {isEditing ? (
        <>
          <Input
            style={{ marginRight: '10px', fontSize: '16px', fontWeight: 300 }}
            value={editedRecordName}
            onChange={handleInputChange}
          />
          <Button onClick={handleSaveClick} variant="outline">
            Save
          </Button>
          <Button onClick={handleCancelClick} style={{ marginLeft: '5px' }} variant="outline">
            Cancel
          </Button>
        </>
      ) : (
        <>
          <Group>
            <Text size="lg" c="#26262C">
              {editedRecordName}
            </Text>

            <Image
              src="/assets/bx-edit.svg"
              alt="edit"
              onClick={handleEditClick}
              style={{ cursor: 'pointer', marginLeft: '16px' }}
            />
          </Group>
        </>
      )}
    </div>
  );
}
