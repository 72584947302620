export function autoFormat(data: string|number|object): string {
    switch (typeof data) {
        case 'string': return data
        case 'boolean': return data ? 'Yes' : 'No'
        case 'object': return formatDateTime(data)
        case 'number': return formatNumber(data)
        case 'undefined': return 'N/A'
        default: return data
    }
}

export function formatNumber(data: number): string {
    const str = data.toFixed(2)
    const [integral, fractional] = str.split('.')
    integral.slice(undefined, -3)
    return integral.replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ '.' + fractional
}

export function formatDateTime(data: any, seperator = "/"): string {
    if (data.day != null && data.month != null && data.year != null)
        return `${String(data.day).padStart(2,'0')}${seperator}${String(data.month).padStart(2,'0')}${seperator}${data.year}`
    else if (data.hour != null ) 
        return `${String(data.hour).padStart(2,'0')}:${String(data.minute ?? 0).padStart(2,'0')}`
    else
        return JSON.stringify(data)
}