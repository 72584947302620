import { Anchor, Breadcrumbs } from '@mantine/core';

interface AppBreadCrumbItem {
  href: string;
  label: string;
}

export function AppBreadCrumbs({ items, seperator = '/' }: { items: AppBreadCrumbItem[]; seperator?: string }) {
  return (
    <Breadcrumbs separator={seperator}>
      {items.map((item, index) => {
        return (
          <Anchor key={`bc_${index}`} href={item.href} fz="16px">
            {item.label}
          </Anchor>
        );
      })}
    </Breadcrumbs>
  );
}
