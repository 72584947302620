import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const LoadingFragmentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 56px;
`

export function LoadingFragment({onComplete, message, polling, pollingInterval }: { onComplete?: () => any, message?: string, polling?: () => Promise<boolean>, pollingInterval?: number }) {
    useEffect(()=>{
        if (polling != null) {
            const intvRef = setInterval(async () => { 
                const finished = await polling?.()
                if (finished == true) {
                    onComplete?.()
                }
            }, (pollingInterval ?? 60) * 1000)

            return () => clearInterval(intvRef)
        }
    }, [])

    return <LoadingFragmentContainer>
        <FontAwesomeIcon className="dim" icon="spinner" spinPulse size="2xl"/>
        <p>{message?.split('\n').map((line,i) => <span key={i}>{line}<br/></span>)}</p>
    </LoadingFragmentContainer>
}
