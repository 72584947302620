import React, { FormEvent, useEffect, useState } from 'react';
import { BankStatementEntry } from '../Widgets/BankStatementEntry';
import { useDispatch, useSelector } from 'react-redux';
import {
  addOrModifyBankStatement,
  initializeWith,
  setStatementPassword,
  uploadBankStatement,
} from '../Features/BankStatementListSlice';
import { BankStatement } from '../Models/BankStatement';
import { Record } from '../Models/Record';
import { AppDispatch, RootState } from '../Store';
import { refreshRecord, resetActiveRecordErrorState, submitRecord } from '../Features/MyRecordsSlice';
import { LoadingFragment } from '../Widgets/LoadingFragment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RecordHeader } from '../Widgets/RecordHeader';
import { PageContent } from '../CommonWidget/PageContent';
import styled from 'styled-components';
import { PageWrapper } from './PageWrapper';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Modal, Stack, Text, Image, Flex, Title } from '@mantine/core';
import { NormalText } from '../Styles/Texts';
import { useClientId, useTenantAlias } from '../hooks/user';
import { useDisclosure } from '@mantine/hooks';
import { AppBreadCrumbs } from '../common/components/BreadCrumbs';
import CopyLinkButton from '../app/customer/[id]/edit/components/CopyLinkButton';
import { getPresignUrl } from '../api/bank-statement';
import { notifications } from '@mantine/notifications';

const FormUploader = styled.form`
  border: 2px dashed var(--fill-disabled, #c8c8d0);
  width: 100%;
  height: 283px;
  position: relative;
`;
const Centered = styled.div<{ $rowGap?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: ${(props) => props.$rowGap ?? '24px'};
`;

export function UploadStatementPage() {
  const activeRecord = useSelector((state: RootState) => state.myRecordsSlice.activeRecord) as Record;
  const cust = useSelector((state: RootState) => state.customerSlice.activeCustomer);
  const statements = useSelector((state: RootState) => state.bankStatementSlice.bankStatementList);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [opened, { open, close }] = useDisclosure(false);
  const alias = useTenantAlias();
  const clientId = useClientId();
  const customerId = queryParams.get('customerId');

  const _refresh = async () => {
    await dispatch(refreshRecord(activeRecord));
    return ['Completed', 'Error'].includes(activeRecord.status);
  };

  useEffect(() => {
    dispatch(initializeWith(activeRecord));
  }, [activeRecord, opened]);

  const handleModalClose = async () => {
    await dispatch(refreshRecord(activeRecord));
    close();
  };

  const errorMessageMapper = (step: string) => {
    switch (step) {
      case 'ReadBasicStatementInfo':
        return 'ไฟล์ไม่ถูกต้อง กรุณาตรวจสอบและส่งข้อมูลอีกครั้ง';
      case 'EstimateIncome':
      case 'EstimateCashFlow':
        return 'ระบบขัดข้อง กรุณาติดต่อเจ้าหน้าที่';
      default:
        return 'ระบบขัดข้อง กรุณาลองใหม่';
    }
  };

  const actionButtonMessageMapper = (step: string) => {
    switch (step) {
      case 'ReadBasicStatementInfo':
        return 'กลับไปลองใหม่';
      case 'EstimateIncome':
      case 'EstimateCashFlow':
        return 'ติดต่อเจ้าหน้าที่';
      default:
        return 'กลับไปลองใหม่';
    }
  };

  const breadCrumbItems = [
    {
      label: 'ข้อมูลลูกค้า',
      href: `/${alias}/customer`,
    },
    {
      label: `${cust.firstname ?? '-'} ${cust.lastname ?? '-'}`,
      href: `/${alias}/customer/${cust._id}/data`,
    },
    {
      label: 'เพิ่มบันทึก',
      href: '',
    },
  ];

  if (!customerId || !clientId) return;

  return (
    <PageWrapper>
      <PageContent>
        <AppBreadCrumbs items={breadCrumbItems} />
        <Flex justify="space-between" mt="24px" mb="24px">
          <RecordHeader record={activeRecord} showStatus={true} />
          <CopyLinkButton clientId={clientId} customerId={customerId} />
        </Flex>

        {
          {
            Draft: <UploaderFragment openModal={open} statements={statements} />,
            Uploaded: (
              <LoadingFragment
                message={'We are getting data from your record.\nIt might take a while.'}
                polling={_refresh}
                pollingInterval={10}
              />
            ),
            Analyzing: (
              <LoadingFragment
                message={'We are analyzing data from your record.\nIt might take a while.'}
                polling={_refresh}
                pollingInterval={10}
              />
            ),
            Completed: <SwitchToReport userId={customerId} recordId={activeRecord.recordId} />,
            Error: <UploaderFragment openModal={open} statements={statements} />,
            Loading: null, // not expected to be here. the loading is just for making a placeholder.
          }[opened ? 'Draft' : activeRecord?.status ?? 'Draft']
        }
      </PageContent>
      <Modal opened={opened} withCloseButton={false} onClose={handleModalClose} centered>
        <Modal.Header>
          <Image src="/assets/alert-circle.svg" />
        </Modal.Header>
        <Modal.Body>
          <Stack>
            <Text fw={500} fz="24px" c={'#26262C'}>
              {errorMessageMapper(activeRecord.error?.step)}
            </Text>
            <Button onClick={handleModalClose}>{actionButtonMessageMapper(activeRecord.error?.step)}</Button>
          </Stack>
        </Modal.Body>
      </Modal>
    </PageWrapper>
  );
}

function UploaderFragment({ openModal, statements }: { openModal: () => void; statements: BankStatement[] }) {
  const [params] = useSearchParams();
  // const statements = useSelector((state: any) => state.bankStatementSlice.bankStatementList);
  const activeRecord = useSelector<any, Record>((state) => state.myRecordsSlice.activeRecord);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const customerId = params.get('customerId');

  useEffect(() => {
    if (!activeRecord) {
      navigate(`/customer/${customerId}/data`);
    }
  }, [activeRecord]);

  const _onSetPassword = (stmt: BankStatement, password: string) => {
    dispatch(setStatementPassword({ statementId: stmt.id, password, s3path: stmt.s3path }));
    dispatch(uploadBankStatement({ fileName: stmt.fileName }));
  };

  const handleSubmitRecord = async () => {
    const res: any = await dispatch(submitRecord(activeRecord));

    if (res.payload?.data?.data?.error?.code === 'JOB_FAILED') {
      openModal();
    }

    dispatch(refreshRecord(activeRecord));
  };

  const downloadStatement = async (statement: BankStatement) => {
    const [err, presignUrl] = await getPresignUrl(statement.s3path);
    if (err) {
      notifications.show({
        title: 'พบปัญหา',
        message: `ไม่สามารถดาวน์โหลดสเตทเมนท์ได้ในขณะนี้ โปรดลองใหม่ในภายหลัง`,
        color: 'red',
      });
      return;
    }

    triggerDownload(presignUrl);
  };

  const triggerDownload = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = 'filename.ext'; // Replace with the desired filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Flex mb="16px" align="baseline">
        <Text fz="18px" fw="500">
          อัปโหลดสเตทเม้นท์ธนาคาร
        </Text>
        <Text ml="8px">สูงสุดถึง 6 ไฟล์</Text>
      </Flex>
      {statements.length < 6 && <UploadPlaceholder />}
      <Text mt="24px" mb="16px">
        อัปโหลดแล้ว {statements.length} ไฟล์
      </Text>
      {statements
        .map((stmt: BankStatement) => ({ ...stmt, progress: stmt.progress }))
        .map((stmt: BankStatement, index) => (
          <BankStatementEntry
            statement={stmt}
            key={`${stmt.id}_${index}`}
            needPassword={stmt.needPassword}
            setPassword={(password: string) => _onSetPassword(stmt, password)}
            handleDownload={async (statement: BankStatement) => await downloadStatement(statement)}
            recordId={activeRecord.recordId}
          />
        ))}

      <Centered style={{ marginTop: '8px' }}>
        <Button onClick={handleSubmitRecord} disabled={!_submissionReadinessCheck(statements)}>
          ส่งข้อมูล
        </Button>
      </Centered>
    </>
  );
}

const TechnicalDetails = styled.pre`
  text-align: left;
  border: 1px solid var(--fill-disabled, #c8c8d0);
  padding: 12px;
  background: #f1f0f0;
  border-radius: 8px;
  max-height: 40em;
  max-width: 70%;
  overflow: scroll;
`;
function ErrorFragment() {
  const activeRecord = useSelector<any, Record>((state) => state.myRecordsSlice.activeRecord);
  const dispatch = useDispatch<AppDispatch>();

  const _onResubmit = async () => {
    dispatch(resetActiveRecordErrorState());
    dispatch(initializeWith(activeRecord));
  };

  return (
    <div className="loading-fragment">
      <Centered>
        {activeRecord?.statusMessage ? (
          <p>{activeRecord?.statusMessage}</p>
        ) : (
          <p>
            Error: There is a problem with the network or there might be server issues.
            <br />
            Please contact your administrator.
          </p>
        )}

        {activeRecord?.error && <TechnicalDetails>{JSON.stringify(activeRecord.error, null, 2)}</TechnicalDetails>}

        <Button onClick={_onResubmit}>
          <FontAwesomeIcon icon="share" /> Retry Submission
        </Button>
      </Centered>
    </div>
  );
}

function SwitchToReport({ userId, recordId }: { userId: string | null; recordId: string | undefined }) {
  const alias = useTenantAlias();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/${alias}/customer/${userId}/data/report/${recordId}`);
  }, []);
  return null;
}

const FileUploaderDisplay = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  & > div > hr {
    border: 0px;
    border-top: 1px solid var(--fill-disabled, #c8c8d0);
    width: 170px;
    height: 1px;
  }
`;

const AlternativeInstruction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9px;
`;
const Link = styled.span`
  color: var(--brand-abacus-primary-red, #ec1e35);
  text-decoration: underline;
  cursor: pointer;
`;
const StatementFileUploaderInput = styled.input`
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  opacity: 0;
`;
function UploadPlaceholder() {
  const dispatch = useDispatch<AppDispatch>();

  const handleFileChange = async (e: FormEvent<HTMLInputElement>) => {
    for (const file of e.currentTarget.files ?? []) {
      const needPass = await _needPassword(file);
      dispatch(addOrModifyBankStatement({ fileName: file.name, file: file, password: '', needPassword: needPass }));
      if (!needPass) dispatch(uploadBankStatement({ fileName: file.name }));
    }
  };

  return (
    <FormUploader>
      <FileUploaderDisplay>
        <NormalText>ลากและวางไฟล์ .pdf ของคุณที่นี่</NormalText>
        <AlternativeInstruction>
          <hr />
          <p>
            หรือกด <Link>ที่นี่</Link> เพื่อเลือกไฟล์
          </p>
          <hr />
        </AlternativeInstruction>
        <p>ขนาดไฟล์สูงสุด 10 MB</p>
      </FileUploaderDisplay>
      <StatementFileUploaderInput type="file" placeholder="" accept=".pdf" multiple onInput={handleFileChange} />
    </FormUploader>
  );
}

function _submissionReadinessCheck(bankStatementList: BankStatement[]) {
  return bankStatementList.length > 0 && bankStatementList.reduce((ready, b) => ready && b.progress === 'Done', true);
}

async function _needPassword(file: File): Promise<boolean> {
  if (file == null) {
    return false;
  }
  const content = await file.text();
  return content.indexOf('/Encrypt') >= 0;
}
