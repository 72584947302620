import styled from 'styled-components'
import { TextInput } from './TextInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, FormEvent } from 'react'

const PasswordInputContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
`
const EyeContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 0.6em;
    color: var(--fill-clickable-default, #5E5E6E);

    & svg {
        cursor: pointer;
    }
`

const PasswordInputInput = styled(TextInput).attrs<{$revealed?: boolean}>(props => ({type: props.$revealed ? 'text': 'password'}))`
    flex-grow: 1;
`

export function PasswordInput(props: {revealable?: boolean, value?: string, placeholder?: string, onInput?: (event: FormEvent<HTMLInputElement>) => any}) {
    const [revealed, setRevealed] = useState(false)
    return <PasswordInputContainer>
        <PasswordInputInput value={props.value} $revealed={revealed} placeholder={props.placeholder} onInput={(e: FormEvent<HTMLInputElement>) => props.onInput?.(e)}/>
        {props.revealable && <EyeContainer><FontAwesomeIcon icon={revealed ? 'eye' : 'eye-slash'} onClick={() => setRevealed(!revealed)}/></EyeContainer>}
    </PasswordInputContainer>
}