import { RecordTransaction } from '../../Models/Record';

export interface MappedTransaction {
  Date: string;
  Time: string;
  Code: string;
  'ABACUS Code': string;
  Channel: string;
  Type: string;
  Amount: number;
  Balance: number;
  Description: string;
}

export function transactionMapper(transactions?: RecordTransaction[]): MappedTransaction[] {
  if (!transactions) return [];
  return transactions.map((t) => {
    return {
      Date: `${t.Date.day}/${t.Date.month}/${t.Date.year}`,
      Time: `${String(t.Time?.hour ?? 0).padStart(2, '0')}:${String(t.Time?.minute ?? 0).padStart(2, '0')}`,
      Code: t.Code,
      'ABACUS Code': t['ABACUS Code'],
      Channel: t.Channel,
      Type: t.Type,
      Amount: t.Amount,
      Balance: t.Balance,
      Description: t.Description,
    };
  });
}
