import React from 'react';

import { UploadStatementPage } from './UploadStatementPage';
import { CustomerManagementPage } from '../app/customer/page';
import { useSelector } from 'react-redux';
import { ReportDetailPage } from './ReportDetailPage';
import { Routes, Route } from 'react-router-dom';
import SignPage from '../Pages/SignPage/SignPage';
import { CustomerEditorPage } from '../app/customer/[id]/edit/page';
import { CustomerDataPage } from '../app/customer/[id]/data/page';
import ReportSummaryPage from '../app/customer/[id]/data/report/page';
import { UserManagementPage } from '../app/user/page';
import { UserDetailPage } from '../app/user/[id]/page';
import ForgetPasswordPage from '../app/forget-password/page';
import { LoginPage } from '../app/login/page';
import UpdatePasswordPage from '../app/update-password/page';

export function Router() {
  const props = useSelector((state: any) => state.routeSlice.props);

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forget-password" element={<ForgetPasswordPage />} />
      <Route path="/update-password" element={<UpdatePasswordPage />} />
      <Route path="/:clientId/upload-statement" element={<UploadStatementPage />} />
      <Route path="/:clientId/report-detail" element={<ReportDetailPage {...props} />} />
      <Route path="/:clientId/sign" element={<SignPage />} />

      <Route path="/:clientId/customer" element={<CustomerManagementPage />} />
      <Route path="/:clientId/customer/:customerId/edit" element={<CustomerEditorPage />} />
      <Route path="/:clientId/customer/:customerId/data" element={<CustomerDataPage />} />
      <Route path="/:clientId/customer/:customerId/data/report/:recordId" element={<ReportSummaryPage {...props} />} />

      <Route path="/:clientId/users" element={<UserManagementPage />} />
      <Route path="/:clientId/users/new" element={<UserDetailPage />} />
      <Route path="/:clientId/users/:id" element={<UserDetailPage isEdit={true} />} />
      <Route path="*" element={<LoginPage />} />
    </Routes>
  );
}
