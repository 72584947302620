import { Button, Group, Popover, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconTrash } from '@tabler/icons-react';
import { useDispatch } from 'react-redux';
import { deleteCustomer } from '../../../Features/CustomerSlice';
import { AppDispatch } from '../../../Store';
import { useNavigate } from 'react-router-dom';
import { useTenantAlias } from '../../../hooks/user';

export function CustomerDeleteButton({ customerId, clientId }: { customerId: string; clientId: string }) {
  const [deleteConfirmationDialogOpened, deleteConfirmationDialogAction] = useDisclosure();
  const dispatch = useDispatch<AppDispatch>();
  const alias = useTenantAlias();
  const navigate = useNavigate();

  const _handleDelete = async () => {
    deleteConfirmationDialogAction.close();
    await dispatch(deleteCustomer(customerId));
    navigate(`/${alias}/customer`);
  };

  return (
    <Popover opened={deleteConfirmationDialogOpened} withArrow closeOnEscape closeOnClickOutside>
      <Popover.Target>
        <Button
          leftSection={<IconTrash />}
          variant="outline"
          color="red"
          style={{ border: 'none' }}
          onClick={() => deleteConfirmationDialogAction.open()}
        >
          ลบข้อมูล
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack gap={10}>
          คุณต้องการลบข้อมูลลูกค้านี้หรือไม่?
          <Group justify="right">
            <Button onClick={_handleDelete}>ลบ</Button>
            <Button onClick={deleteConfirmationDialogAction.close} variant="outline">
              ไม่ลบ
            </Button>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}
