import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const BreadCrumpContainer = styled.div`
  color: var(--fill-clickable-default, #5e5e6e);
  margin-bottom: 32px;
  cursor: pointer;
  @media print {
    display: none;
  }
`;

const BreadCrumpItem = styled.span`
  color: var(--fill-clickable-default, #5e5e6e);
  margin-left: 16px;
`;

export function BreadCrump({ items }: { items: { label: string; link: string; props?: any }[] }) {
  // unused
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <BreadCrumpContainer>
      <FontAwesomeIcon icon="chevron-left" onClick={() => navigate(items.at(-1)?.link ?? '')} />
      {items.map((item, i) => (
        <BreadCrumpItem
          key={i}
          className="bread-crump-item"
          onClick={() => navigate(items.at(-1)?.link ?? '', { replace: true, state: item.props })}
        >
          {item.label}
        </BreadCrumpItem>
      ))}
    </BreadCrumpContainer>
  );
}
