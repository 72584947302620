import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../Features/UserSlice';
import { AppDispatch, RootState } from '../../Store';
import styled from 'styled-components';
import { TextInput } from '../../CommonWidget/TextInput';
import { PasswordInput } from '../../CommonWidget/PasswordInput';
import { Box, Button, Flex, Image, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const SigninPane = styled.div`
  flex-grow: 1;
  background: #fff;
  width: 50%;
  min-width: 500px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SigninBox = styled.div`
  width: 414px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: just;

  & input {
    padding: 8px 12px;
    gap: 8px;
    margin-bottom: 24px;
    border: 1px solid #dedee3;
    border-radius: 8px;
  }

  & label {
    text-align: left;
    color: #5e5e6e;
    margin-bottom: 2px;
    font-size: 14px;
  }
`;

const Topic = styled.h1`
  font-size: 30px;
  font-family: Avenir, Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 500;
  color: #ec1e35;
`;
const ErrorMessage = styled.div`
  color: #ec1e35;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
`;

export function LoginPage() {
  const dispatch = useDispatch<AppDispatch>();
  const activeUser = useSelector((state: RootState) => state.userSlice.activeUser);
  const error = useSelector((state: RootState) => state.userSlice.error);
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (activeUser != null) {
      navigate(`/${activeUser.tenant?.alias}/customer`);
    }
  }, [activeUser]);

  const _performLogin = () => {
    dispatch(login({ userId, password }));
  };

  const handleForgetPassword = () => {
    navigate(`/forget-password`);
  };

  return (
    <Flex h="100vh" justify="end" bg="#f9f9fa">
      <Flex p="29px 26px" style={{ flexGrow: 1 }}>
        <Box w="224px" p="0 37.8px ">
          <Image src="/assets/abacus-digital-logo.svg" alt="logo" />
        </Box>
      </Flex>

      <SigninPane>
        <SigninBox>
          <Topic>Sign in to Abacus Digital</Topic>
          <label className="label" htmlFor="email">
            Email
          </label>
          <TextInput placeholder="...@abacusdigital.com" onInput={(e) => setUserId(e.currentTarget.value)} />

          <label className="label" htmlFor="password">
            Password
          </label>
          <PasswordInput placeholder="Your password" onInput={(e) => setPassword(e.currentTarget.value)} revealable />

          <Button onClick={_performLogin} disabled={userId.length === 0 || password.length === 0}>
            Sign in
          </Button>

          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Text
            fw={500}
            c="purple.6"
            style={{ cursor: 'pointer' }}
            w="fit-content"
            m="24px auto"
            onClick={handleForgetPassword}
          >
            ลืมรหัสผ่าน?
          </Text>
        </SigninBox>
      </SigninPane>
    </Flex>
  );
}
