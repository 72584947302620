import { Button, Flex, Paper, Text, Image, Table, Group } from '@mantine/core';
import { Record, RecordTransaction } from '../../../../../../Models/Record';
// @ts-ignore
import classes from './StatementSection.module.css';
import { useMemo, useState } from 'react';
import { formatDateTime } from '../../../../../../Utils/FormatUtil';
import { MappedTransaction, transactionMapper } from '../../../../../../Utils/mapper/transactionMapper';
import { exportCSV } from '../../../../../../Utils/export';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CustomerInfoStaged } from '../../../../../../Models/CustomerInfo';
import { mask } from '../../../../../../Utils/mask';
dayjs.extend(utc);
dayjs.extend(timezone);

const groupData = (data?: RecordTransaction[]) => {
  if (!data) return {};
  const groupedData = data.reduce((groups, item) => {
    const groupKey = item['$group'];
    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }
    groups[groupKey].push(item);
    return groups;
  }, {} as { [key: string]: RecordTransaction[] });

  return groupedData;
};

export default function StatementSection({ record, customer }: { record: Record; customer: CustomerInfoStaged }) {
  const data = record.result?.transactions;
  const grouped = useMemo(() => groupData(data), [data]);
  const [group, setGroup] = useState<string | undefined>(undefined);

  const handleGroupClick = (group: string) => {
    setGroup(group);
  };

  const handleBackClick = () => {
    setGroup(undefined);
  };

  const handleExportClick = async () => {
    const transactions = transactionMapper(data);
    if (transactions.length === 0) return;
    const csvData = '\uFEFF' + convertArrayToCSV(transactions, record.dateSubmitted, customer);
    await exportCSV(
      csvData,
      `statement_${mask(customer.cid || '', '999XXXXXX9999', 'X')}_${dayjs(record.dateSubmitted)
        .tz('Asia/Bangkok')
        .format('YYYYMMDD')}`
    );
  };

  const convertArrayToCSV = (data: MappedTransaction[], completedDate: string, customer: CustomerInfoStaged) => {
    let basicInfo = `Customer name,${customer.firstname} ${customer.lastname}\nEmail, ${customer.email}\nID,\t${
      customer.cid
    }\nJob completed date,"${dayjs(completedDate).tz('Asia/Bangkok').format('YYYY-MMM-DD HH:mm:ss')}"\n,\n`;

    const header = Object.keys(data[0]).join(',') + '\n';
    const rows = data
      .map((obj) =>
        Object.values(obj)
          .map((value) => `"${value}"`)
          .join(',')
      )
      .join('\n');
    return basicInfo + header + rows;
  };

  if (!data) return <></>;
  return (
    <section style={{ marginTop: '32px' }}>
      {!group ? (
        <>
          <Group justify="space-between">
            <Text size="xl" fw={500} c="purple.6">
              Transactions
            </Text>
            <Button
              variant="transparent"
              leftSection={<Image src="/assets/share.svg" alt="export" />}
              onClick={handleExportClick}
            >
              Export csv
            </Button>
          </Group>
          <Flex direction="column" gap="md" mt="24px">
            {Object.keys(grouped).map((key, i) => (
              <StatementList key={`${key}_${i}`} label={key} handleClick={handleGroupClick}></StatementList>
            ))}
          </Flex>
        </>
      ) : (
        <StatementDetail data={grouped[group]} label={group} onBackClick={handleBackClick} />
      )}
    </section>
  );
}

function StatementDetail(props: { label: string; data: RecordTransaction[]; onBackClick: () => void }) {
  const { onBackClick, data, label } = props;

  return (
    <Group>
      <Button
        variant="transparent"
        onClick={onBackClick}
        style={{
          padding: 0,
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          boxShadow: '0px 8px 16px 0px rgba(222, 222, 227, 0.4)',
        }}
      >
        <Image src="/assets/chevron_left.svg" />
      </Button>
      <Group>
        <Image m="auto" src="/assets/library_books.svg" />
        <Text size="lg">{label}</Text>
      </Group>
      <Table withRowBorders={false} withColumnBorders verticalSpacing="md">
        <Table.Thead>
          <Table.Tr>
            <Table.Th c="purple.6" style={{ border: 0 }}>
              Date
            </Table.Th>
            <Table.Th c="purple.6" style={{ border: 0 }}>
              Time
            </Table.Th>
            <Table.Th c="purple.6" style={{ border: 0 }}>
              Code
            </Table.Th>
            <Table.Th c="purple.6" style={{ border: 0 }}>
              ABACUS Code
            </Table.Th>
            <Table.Th c="purple.6" style={{ border: 0 }}>
              Channel
            </Table.Th>
            <Table.Th c="purple.6" style={{ border: 0 }}>
              Type
            </Table.Th>
            <Table.Th c="purple.6" style={{ border: 0 }}>
              Amount
            </Table.Th>
            <Table.Th c="purple.6" style={{ border: 0 }}>
              Balance
            </Table.Th>
            <Table.Th c="purple.6" style={{ border: 0 }}>
              Description
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data.map((d, i) => (
            <Table.Tr>
              <Table.Td>{formatDateTime(d.Date, '-')}</Table.Td>
              <Table.Td>{formatDateTime(d.Time, '-')}</Table.Td>
              <Table.Td>{d.Code}</Table.Td>
              <Table.Td>{d['ABACUS Code']}</Table.Td>
              <Table.Td>{d.Channel}</Table.Td>
              <Table.Td>{d.Type}</Table.Td>
              <Table.Td>{d.Amount}</Table.Td>
              <Table.Td>{d.Balance}</Table.Td>
              <Table.Td>{d.Description}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Group>
  );
}

function StatementList(props: { label: string; handleClick: (group: string) => void }) {
  const { label, handleClick } = props;
  return (
    <Button
      leftSection={<Image src="/assets/library_books.svg" />}
      rightSection={<Image src="/assets/chevron_right.svg" />}
      classNames={classes}
      variant="outline"
      justify="flex-start"
      fullWidth
      size="xl"
      style={{
        border: '1px solid #DEDEE3',
        fontWeight: 300,
        color: '#5E5E6E',
        fontSize: '16px',
      }}
      styles={{
        label: {
          marginLeft: '14px',
        },
      }}
      onClick={() => handleClick(label)}
    >
      {label}
    </Button>
  );
}
