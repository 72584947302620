type ValidatorFunction = (value: string) => null | any;

export function isRequired(message: string, validator?: ValidatorFunction): ValidatorFunction {
  return (value: string) => {
    if (!value || value === '') {
      return message;
    }
    if (validator) {
      return validator(value);
    }
    return null;
  };
}

export function isOptional(validatorIfPresent?: ValidatorFunction): ValidatorFunction {
  return (value: string) => {
    if (value == null) return null;
    if (validatorIfPresent) {
      return validatorIfPresent(value);
    }
    return null;
  };
}

export function isThai(): ValidatorFunction {
  return (value: string) => {
    if (value === '') return null;
    if (value.match(/[^ก-๙\s\-]/)) {
      return 'ต้องเป็นภาษาไทย';
    }
    return null;
  };
}

export function isEnglish(): ValidatorFunction {
  return (value: string) => {
    if (value === '') return null;
    if (value.match(/[^a-zA-Z\s\-]/)) {
      return 'ต้องเป็นภาษาอังกฤษ';
    }
    return null;
  };
}

export function isThailandPhoneNumber(): ValidatorFunction {
  return (value: string) => {
    if (!value || value === '') return null;
    if (value.match(/[^0-9\- ()]/)) {
      return 'ใส่เบอร์โทรศัพท์ให้ถูกต้อง (เช่น 081-234-5678)';
    }
    return null;
  };
}

export function isLaserId(): ValidatorFunction {
  return (value: string) => {
    if (value === '') return null;
    if (!value.match(/[A-Z]{2}[0-9]{1}\-?[0-9]{7}\-?[0-9]{2}/)) {
      return 'เลขหลังบัตรต้องเป็นดังตัวอย่าง: AA1-1234567-12';
    }
    return null;
  };
}

export function isThaiId(): ValidatorFunction {
  return (value: string) => {
    if (!value || value === '') return 'กรุณาใส่วันเกิดบนบัตรประชาชน';
    if (!value.match(/^\d{1}-?\d{4}-?\d{5}-?\d{2}-?\d{1}$/)) {
      return 'เลขบัตรประจำตัวประชาชนไม่ถูกต้อง';
    }
    return null;
  };
}

export function emailValidator() {
  return (value: string) => {
    if (!value || value === '') return ' กรุณาใส่อีเมล';
    if (!value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/)) {
      return 'อีเมลไม่ถูกต้อง';
    }
    return null;
  };
}
