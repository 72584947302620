import { Button, Card, Divider, Flex, Group, Image, Text } from '@mantine/core';
import { PageWrapper } from '../../Views/PageWrapper';
import { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const SignPage = () => {
  const canvasRef = useRef<typeof SignatureCanvas.prototype | null>(null);
  const [svgImage, setSvgImage] = useState<string | null>(null);

  const saveSVG = () => {
    if (!canvasRef.current) return;
    const svgData = canvasRef.current.toDataURL('image/svg+xml');
    setSvgImage(svgData);
  };

  const clearSVG = () => {
    if (!canvasRef.current) return;
    canvasRef.current.clear();
    setSvgImage(null);
  };

  return (
    <PageWrapper>
      <Flex>
        <div style={{ border: '1px solid black', width: '502px', height: '252px' }}>
          <SignatureCanvas
            ref={canvasRef}
            backgroundColor="#FFFFFF"
            canvasProps={{ height: '250px', width: '500px' }}
          />
        </div>
        <Button onClick={saveSVG}> Save </Button>
        <Button onClick={clearSVG} variant="outline">
          Clear
        </Button>
      </Flex>
      {svgImage && (
        <Flex w="40%" pos="relative" mt="lg">
          <Text size="lg"> Saved signature</Text>
          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <Card.Section>
              <Image src={svgImage} alt="SVG from Canvas" fit="contain" />
            </Card.Section>
            <Group>
              <Text size="md">Signature</Text>
            </Group>
          </Card>
        </Flex>
      )}
    </PageWrapper>
  );
};

export default SignPage;
