export enum Role {
  Customer = 'customer',
  ClientAdmin = 'client-admin',
  Admin = 'admin',
  SuperAdmin = 'super-admin',
}

export interface UserInfo {
  username: string;
  userId: string;
  roles?: Role[];
  avatarUrl?: string;
  authToken?: string;
  tenant: {
    clientId: string;
    alias: string;
    redirectURL?: string;
  };
}
