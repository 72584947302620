import { ReactNode } from 'react';
import { RolePermissionContextValue, useRole } from '../../context/role-context';
import { Role } from '../../Models/UserInfo';

const hasPermission = ({ scopes, roles }: { scopes: Role[]; roles: RolePermissionContextValue }) => {
  return scopes.every((scope) => roles[scope]);
};

export function RoleGate({ children, scopes }: { scopes: Role[]; children: ReactNode }) {
  const roles = useRole();
  const permissionGranted = hasPermission({ scopes, roles: roles || {} });
  if (!permissionGranted) {
    return null;
  }

  return children;
}
