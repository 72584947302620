import { Table, Text, Group, Stack, Image } from '@mantine/core';
import { useState } from 'react';
import { CustomerInfo } from '../../../Models/CustomerInfo';
import { Grade } from '../../../Widgets/Grade';
import { listRecords } from '../../../Features/MyRecordsSlice';
import { useDispatch } from 'react-redux';
import { ScoreGrid } from '../../../Widgets/ScoreGrid';
import styled from 'styled-components';
import day from 'dayjs';
import { AppDispatch } from '../../../Store';
import { Record } from '../../../Models/Record';
import { useTenantAlias } from '../../../hooks/user';

export function CustomerTable({ data }: { data: CustomerInfo[] }) {
  return (
    <Table
      withRowBorders
      highlightOnHover={data.length > 0}
      verticalSpacing="sm"
      horizontalSpacing="md"
      layout="fixed"
      w="100%"
    >
      <Table.Thead>
        <Table.Tr style={{ border: 0 }}>
          <Table.Th w="35%" fw={300}>
            ชื่อลูกค้า
          </Table.Th>
          <Table.Th w="20%" fw={300}>
            เลขประจำตัวประชาชน
          </Table.Th>
          <Table.Th w="15%" fw={300}>
            วันที่แก้ไข
          </Table.Th>
          <Table.Th w="15%" fw={300}>
            จำนวนบันทึก
          </Table.Th>
          <Table.Th w="10%" fw={300}>
            เกรด
          </Table.Th>
          <Table.Th w="5%" fw={300}></Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {data?.length ? (
          data?.map((customer, row) => <CustomerRow key={`${customer._id}_${row}`} data={customer} row={row} />)
        ) : (
          <Table.Tr>
            <Table.Td key={0} colSpan={100} align="center">
              <Image src="/assets/not_found.svg" w="175px" h="135px" mt="149px" />
              <Text size="lg" mt="8px">
                ไม่พบข้อมูล
              </Text>
            </Table.Td>
          </Table.Tr>
        )}
      </Table.Tbody>
    </Table>
  );
}

function CustomerRow(props: { data: CustomerInfo; row: number }) {
  const { data: customer } = props;
  const [expand, setExpand] = useState<boolean>(false);
  const [details, setDetails] = useState<Record | undefined>(undefined);
  const dispatch = useDispatch<AppDispatch>();
  const alias = useTenantAlias();

  const handleExpandClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpand((prev) => !prev);
    const data: any = await dispatch(listRecords({ clientId: customer?.clientId!, customerId: customer._id! }));
    setDetails(data.payload ? data.payload.data.data[0] : undefined);
  };

  return (
    <>
      <Table.Tr
        style={{ cursor: 'pointer', borderBottom: expand ? 'none' : '' }}
        onClick={() => window.open(`/${alias}/customer/${customer._id}/data`, '_blank')}
        h="72px"
      >
        <Table.Td>
          <Group wrap="nowrap">
            <Image src="/assets/avatar-placeholder.svg" alt="avatar" w="32px" h="32px" fit="contain" />
            <Stack gap="0" style={{ overflow: 'hidden' }}>
              <Text fw="500" c="#26262C" fz="14px">
                {customer.firstname ?? '-'} {customer.lastname}
              </Text>
              <Text fz="14px" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {customer.email}
              </Text>
            </Stack>
          </Group>
        </Table.Td>
        <Table.Td c="#26262C" fz="14px">
          {customer.cid ?? '-'}
        </Table.Td>
        <Table.Td c="#26262C" fz="14px">
          {day(customer.lastModified).format('DD MMM YYYY')}
        </Table.Td>
        <Table.Td c="#26262C" fz="14px">
          {customer.records?.length ?? 0}
        </Table.Td>
        <Table.Td>
          <Grade customer={customer} />
        </Table.Td>
        <Table.Td onClick={handleExpandClick}>
          <Image src="/assets/expand_dropdown.svg" w="20px" h="20px" />
        </Table.Td>
      </Table.Tr>
      <DetailRow expanded={expand} details={details} />
    </>
  );
}

function DetailRow({ expanded, details }: { expanded: boolean; details: Record | undefined }) {
  return (
    <Table.Tr style={{ margin: 0, border: 0, borderRadius: '0px 0px 8px 8px' }}>
      <Table.Td colSpan={100} style={{ margin: 0, padding: 0, border: 0 }}>
        <AnimatableRow $expanded={expanded}>
          <ScoreGrid record={details} />
        </AnimatableRow>
      </Table.Td>
    </Table.Tr>
  );
}

const AnimatableRow = styled.div<{ $expanded: boolean }>`
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  max-height: ${(props) => (props.$expanded ? '20em' : '0px')};
  border-radius: 0px 0px 8px 8px;
  background-color: #f9f9fa;
`;
