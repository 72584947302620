import { Group, Button } from '@mantine/core';
import { CustomerDeleteButton } from '../../../components/CustomerDeleteButton';

export default function ButtonBar({
  customerId,
  clientId,
  onCancel,
  onSubmit,
}: {
  customerId: string;
  clientId: string;
  onCancel: () => void;
  onSubmit: () => void;
}) {
  return (
    <Group justify="space-between">
      <Group>
        <CustomerDeleteButton customerId={customerId} clientId={clientId} />
      </Group>
      <Group w="50%" justify="end">
        <Button variant="outline" onClick={onCancel} w="45%" maw="208px">
          ยกเลิก
        </Button>
        <Button onClick={onSubmit} w="45%" maw="208px">
          บันทึกข้อมูล
        </Button>
      </Group>
    </Group>
  );
}
