import { Box, Center, Pagination, Title } from '@mantine/core';
import { UserTable } from './UserTable';
import { PaginationResponse, User, useTenantAlias } from '../../../hooks/user';
import { useModals } from '@mantine/modals';
import { deleteUser } from '../../../api/user';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function UserContent({
  users,
  page,
  handlePageChange,
}: {
  users: PaginationResponse<User[]>;
  page: string;
  handlePageChange: (pageNumber: number) => void;
}) {
  const modals = useModals();
  const [userList, setUserList] = useState<User[]>(users.data);
  const navigate = useNavigate();
  const alias = useTenantAlias();

  const handleDelete = (user: User) => {
    modals.openConfirmModal({
      styles: {
        content: {
          padding: '24px',
        },
      },
      children: (
        <>
          <Title fw="500" fz="24px" c="purple.6" ta="left" mb="24px">
            คุณต้องการจะลบข้อมูลผู้ใช้งานนี้ใช่หรือไม่
          </Title>
        </>
      ),
      withCloseButton: false,
      size: 'auto',
      centered: true,
      labels: { confirm: 'ลบ', cancel: 'ไม่ลบ' },
      confirmProps: { w: '48%' },
      cancelProps: { w: '48%' },
      groupProps: { justify: 'space-around' },
      onConfirm: async () => await confirmDelete(user),
    });
  };

  const confirmDelete = async (user: User) => {
    const [err] = await deleteUser(user);
    if (err) {
      notifications.show({
        title: 'พบปัญหา',
        message: `พบปัญหาระหว่างการลบผู้ใช้งาน โปรดลองใหม่ในภายหลัง`,
        color: 'red',
      });
      return;
    }
    notifications.show({ title: 'ลบผู้ใช้งาน', message: `ลบผู้ใช้งานเรียบร้อยแล้ว`, color: 'green' });
    setUserList(users.data.filter((u) => u.userId !== user.userId));
    return;
  };

  const handleRowClick = (userId: string) => {
    navigate(`/${alias}/users/${userId}`);
  };

  return (
    <>
      <Box style={{ flexGrow: 1 }}>
        <UserTable data={userList} handleDelete={handleDelete} handleRowClick={handleRowClick} />
      </Box>
      <Center>
        <Pagination value={parseInt(page, 10)} onChange={handlePageChange} total={users.totalPage} />
      </Center>
    </>
  );
}
