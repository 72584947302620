import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useReducer } from "react"
import styled from 'styled-components'
import { Button } from "./Button";

const ItemSelectorContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    & button {
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #F1F1F1;
        background: #FFF;
        color: var(--fill-clickable-default);
        width: 38px;
        height: 38px;

        &:disabled {
            color: var(--fill-disabled);
            background-color: #FFF;
        }
    }
`

const ItemDisplay = styled.div`
padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid var(--text-disabled, #C8C8D0);
    background: var(--text-invert, #FFF);

    color: var(--fill-clickable-default, #5E5E6E);
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    height: 38px;
    box-sizing: border-box;

    white-space: nowrap;
`

export function ItemSelector({ selectedIndex, items, onItemSelected }: { selectedIndex?: number, items: string[], onItemSelected?: (selectedItem: number) => any}) {
    const [selectedItem, dispatch] = useReducer((state: number, action: 'prev' | 'next') => {
        switch (action) {
            case 'prev': return state - 1
            case 'next': return state + 1
        }
    }, selectedIndex ?? 0)

    useEffect(() => {
        onItemSelected?.(selectedItem)
    }, [selectedItem, onItemSelected])

    return <ItemSelectorContainer>
        <Button title="prev" onClick={() => dispatch('prev')} disabled={selectedItem === 0}><FontAwesomeIcon icon="angle-left"/></Button>
        <ItemDisplay>{items[selectedItem]}</ItemDisplay>
        <Button title="next" onClick={() => dispatch('next')} disabled={selectedItem >= items.length - 1}><FontAwesomeIcon icon="angle-right"/></Button>
    </ItemSelectorContainer>
}