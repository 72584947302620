import { Badge } from "@mantine/core"
import { CustomerInfo } from "../Models/CustomerInfo"

const gradeColors: {[grade:string]: string} = {
    'A': '#14B88C',
    'B': '#FB8F23',
    'C': '#fb5923',
    'D': '#C8C8D0',
    'F': '#C8C8D0',
    '' : '#C8C8D0',
}


export function Grade({customer}: { customer: CustomerInfo}) {
    return <Badge color={gradeColors[customer.grade ?? '']}>{customer.grade ?? '-'}</Badge>
}