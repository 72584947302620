import { combineReducers, configureStore } from '@reduxjs/toolkit';

import BankStatementListSlice from './Features/BankStatementListSlice';
import RouteSlice from './Features/SimpleRouter';
import MyRecordsSlice from './Features/MyRecordsSlice';
import UserSlice from './Features/UserSlice';
import RawRecordSlice from './Features/RawRecordSlice';
import CustomerSlice from './Features/CustomerSlice';
import recordSlice from './Features/recordSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
};

const reducers = combineReducers({
  routeSlice: RouteSlice,
  bankStatementSlice: BankStatementListSlice,
  myRecordsSlice: MyRecordsSlice,
  customerSlice: CustomerSlice,
  userSlice: UserSlice,
  rawRecordSlice: RawRecordSlice,
  recordSlice,
});
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // this prevents console error indicating the actions
      // from axios headers are non-serializable
      // This can be improved by pointing specific path to
      // serializable check middleware.
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
