import { Paper, Button, Center, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteRecord, listRecords, setActiveRecord } from '../../../../../Features/MyRecordsSlice';
import { useTenantAlias } from '../../../../../hooks/user';
import { CustomerInfoStaged, CustomerRecord } from '../../../../../Models/CustomerInfo';
import { AppDispatch, RootState } from '../../../../../Store';
import { HeaderWithButtons } from '../../../../../Styles/HeaderWithButtons';
import { NormalText } from '../../../../../Styles/Texts';
import CreateNewRecordModal from './CreateNewRecordModal';
import Record from './Record';
import { useEffect } from 'react';
import { loadActiveCustomer } from '../../../../../Features/CustomerSlice';

export default function CustomerDocumentSection(props: { customer: CustomerInfoStaged }) {
  const { customer } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const records = useSelector((state: RootState) => state.myRecordsSlice.myRecords);
  const [createRecordOpened, createRecordAction] = useDisclosure();

  const alias = useTenantAlias();

  const gotoRecord = (rec: CustomerRecord) => {
    const fullRec = records.find((r: any) => r.recordId === rec.recordId);
    if (!fullRec) {
      return;
    }
    dispatch(setActiveRecord(fullRec));
    const isCompleted = fullRec.status === 'Completed';
    const path = isCompleted
      ? `/${alias}/customer/${customer._id}/data/report/${fullRec.recordId}`
      : `/${alias}/upload-statement?customerId=${customer._id}`;
    navigate(path);
  };

  const handleDeleteRecord = async (recordId: string) => {
    await dispatch(deleteRecord({ clientId: customer.clientId, recordId: recordId }));
    window.location.reload();
  };

  return (
    <Paper radius="lg" p="xl" my="lg" style={{ boxShadow: '0px 8px 16px 0px rgba(222, 222, 227, 0.4)' }}>
      <HeaderWithButtons>
        <Title order={2} c="#26262C">
          บันทึกเอกสาร
        </Title>

        <Button
          onClick={() => createRecordAction.open()}
          variant="outline"
          leftSection={<IconPlus size="1.2em" />}
          disabled={customer?.records && customer.records.length > 0}
        >
          สร้างบันทึก
        </Button>
      </HeaderWithButtons>
      {(!customer.records || customer.records?.length === 0) && (
        <div>
          <Center>
            <NormalText>ยังไม่มีเอกสาร</NormalText>
          </Center>
        </div>
      )}

      {customer.records?.map((record) => {
        return (
          <Record key={record.recordId} record={record} handleClick={gotoRecord} handleDelete={handleDeleteRecord} />
        );
      })}
      <CreateNewRecordModal createRecordAction={createRecordAction} createDialogOpen={createRecordOpened} />
    </Paper>
  );
}
