import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import styled from 'styled-components'
import { Button } from './Button'

export type PaginationParams = {
    numItems?: number,
    currentPage: number,
    perPage: number,
    onPageChange: (page: number) => any,
    maxButtons?: number,
    content?: any[],
    onFilteredContent?: (filtered: any[]) => any,
}

const PaginationContainer = styled.div`
    margin: 32px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
    justify-content: center;
`

const PaginationButton = styled(Button)<{$active?: boolean}>`
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
    background: #FFF;

    color: var(--brand-abacus-primary-red, var(--brand-abacus-primary-red, #EC1E35));
    font-family: Helvetica;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:disabled {
        background: #FFF;
        color: var(--fill-disabled);
    }

    &:hover:disabled {
        background: #fff;
    }

    &:hover {
        background: #ffe7e7;
    }

    ${ props => props.$active && `
        background: var(--brand-abacus-primary-red);
        color: #fff;
        cursor: default;
        &:hover {
            background: var(--brand-abacus-primary-red);
        }
    }`}
`

const PaginationDots = styled.div`
    color: var(--brand-abacus-primary-red, var(--brand-abacus-primary-red, #EC1E35));
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px;
`

/**
 * Usage:
 *      Set the content to the entire list of items. The `currentPage` is a state variable that is updated by the caller, usually by subscribing
 *      to the `onPageChange` event. 
 * 
 *      if `content` is given, the Pagination widget will fire `onFilteredContent` to set the list of items to be displayed on the current page.
 *      the `onFilteredContent` will be fired once on mounted, and subsequently after onPageChange. Other event such as change in perPage or content
 *      parameter will also trigger onFilteredContent.
 * 
 *      Note that if `content` is provided, the `numItems` is ignored.
 * 
 *      Use of `content` is optional. Caller may not want the Pagination widget to control how the content is displayed. In this case,
 *      do not send the `content` and `onFilteredContent` parameters. In this case, `numItems` must be provided.
 * @param _ see PaginationParams definition for more details
 * @returns 
 */
export function Pagination({ numItems, currentPage, perPage, onPageChange, maxButtons, content, onFilteredContent }: PaginationParams) {
    const _numItems = content?.length ?? numItems ?? 0
    const pages = Math.ceil(_numItems / perPage)
    const _maxButtons = maxButtons ?? 6

    useEffect(() => {
        if (content != null) 
            onFilteredContent?.(
                content.filter((_,i) => i >= currentPage * perPage && i < (currentPage+1) * perPage)
            )
    }, [content, currentPage, perPage, onFilteredContent])

    const pageItems = new Array(pages).fill(0).map((_, i) => i)
    const pageItemsLeading = pageItems.filter(i => i >= currentPage - _maxButtons/2 && i <= currentPage + _maxButtons/2)
    const pageItemsTrailing = pageItems.filter(i => i === pages - 1)

    return <PaginationContainer>
        <PaginationButton title="begin" disabled={currentPage === 0} onClick={() => onPageChange(0)}><FontAwesomeIcon icon="angles-left" /></PaginationButton>
        <PaginationButton type="button" title="prev" disabled={currentPage === 0} onClick={() => onPageChange(currentPage - 1)}><FontAwesomeIcon icon="angle-left" /></PaginationButton>
        { pageItemsLeading.includes(0) ? <></> : <PaginationDots>...</PaginationDots> }
        { pageItemsLeading.map((i) => <PaginationButton key={i} $active={currentPage === i} type="button" onClick={() => onPageChange(i)}>{i + 1}</PaginationButton>)}
        { pageItemsLeading.includes(pageItems.at(-1) as number) ? <></> : <PaginationDots>...</PaginationDots> }
        { pageItemsLeading.includes(pageItems.at(-1) as number) ? <></> : pageItemsTrailing.map((i) => <PaginationButton key={i} $active={currentPage === i} type="button" onClick={() => onPageChange?.(i)}>{i + 1}</PaginationButton>)}
        <PaginationButton title="next" disabled={currentPage >= pages - 1} onClick={() => onPageChange(currentPage + 1)}><FontAwesomeIcon icon="angle-right"/></PaginationButton>
        <PaginationButton title="end" disabled={currentPage >= pages - 1} onClick={() => onPageChange(pages - 1)} ><FontAwesomeIcon icon="angles-right"/></PaginationButton>
    </PaginationContainer>
}