import axios from 'axios';
import { APIResponse } from './api';

export const refreshToken = async (customerId: string, clientId: string) => {
  try {
    const res = await axios.post<APIResponse<string>>(
      `/api/internal/customer/auth/${clientId}/${customerId}/refresh-token`
    );
    return res.data.data;
  } catch (err) {
    return;
  }
};
