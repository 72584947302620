import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const getRawResponse = createAsyncThunk(`rawRecord/raw`, async (params: { recordId: string; userId: string }) => {
  const raw = await axios.get(`/api/internal/record/${params.userId}/${params.recordId}/raw`);
  return raw;
});

const rawRecordSlice = createSlice({
  name: 'rawRecord',
  initialState: {
    activeRecordId: null as string | null,
    activeUserId: null as string | null,
    rawData: null as any,
    errorMessage: null as string | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRawResponse.pending, (state, action) => {
      state.errorMessage = null;
      state.rawData = null;
    });

    builder.addCase(getRawResponse.fulfilled, (state, action) => {
      const raw = action.payload.data;
      state.rawData = raw;
      state.activeRecordId = action.meta.arg.recordId;
      state.activeUserId = action.meta.arg.userId;
      state.errorMessage = null;
    });

    builder.addCase(getRawResponse.rejected, (state, action) => {
      state.errorMessage = action.error.message ?? null;
    });
  },
});

export default rawRecordSlice.reducer;
export { getRawResponse };
