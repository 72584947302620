import { Box, Table, Text, Flex } from '@mantine/core';
import { RecordResult, RecordResultOverview } from '../../../../../../Models/Record';

export default function OverviewSection(props: { data?: RecordResultOverview[] }) {
  const { data } = props;

  if (!data) return <></>;
  return (
    <section style={{ marginTop: '16px' }}>
      <Text size="xl" fw={500} c="purple.6">
        Overview
      </Text>
      <Box
        style={{
          border: '1px solid #DEDEE3',
          borderRadius: '8px',
          marginTop: '24px',
        }}
      >
        <Table withRowBorders={false} verticalSpacing="md">
          <Table.Thead>
            <Table.Tr>
              <Table.Th fw={300}>Account owner</Table.Th>
              <Table.Th fw={300}>Account number</Table.Th>
              <Table.Th fw={300}>Bank code</Table.Th>
              <Table.Th fw={300}>Branch name</Table.Th>
              <Table.Th fw={300}>Authenticity confidence</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {data.map((o, i) => {
              return (
                <Table.Tr fz="lg" key={`overview_${i}`}>
                  <Table.Td fw={500} c="#26262C">
                    {o['Account Owner']}
                  </Table.Td>
                  <Table.Td fw={500} c="#26262C">
                    {o['Account No.']}
                  </Table.Td>
                  <Table.Td fw={500} c="#26262C">
                    {o['Bank Code']}
                  </Table.Td>
                  <Table.Td fw={500} c="#26262C">
                    {o['Branch Name']}
                  </Table.Td>
                  <Table.Td fw={500} c="#26262C">
                    {o['Authenticity Confidence']}
                  </Table.Td>
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
      </Box>
    </section>
  );
}
