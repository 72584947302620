import { Card, Flex, Group, Popover, ActionIcon, Stack, Button, Image, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { StatusBadge } from '../../../../../Widgets/StatusBadge';
import { CustomerRecord } from '../../../../../Models/CustomerInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Store';
import { useDisclosure } from '@mantine/hooks';

export default function Record({
  record,
  handleClick,
  handleDelete,
}: {
  record: CustomerRecord;
  handleClick: (record: CustomerRecord) => void;
  handleDelete: (recordId: string) => void;
}) {
  const records = useSelector((state: RootState) => state.myRecordsSlice.myRecords);
  const recordWithStatus = records.find((r: any) => r.recordId === record.recordId);
  const [deleteDialogOpened, deleteDialogAction] = useDisclosure();

  return (
    <Card
      style={{ border: '1px #ccc solid', marginBottom: '1em', cursor: 'pointer' }}
      onClick={() => handleClick(record)}
    >
      <Flex justify="space-between">
        <Group justify="space-between" w="90%">
          <Group>
            <Image src="/assets/library_books.svg" />
            <Text size="md" fw="500">
              {record.name}
            </Text>
          </Group>
          <Group align="flex-end">
            <Text fz="14px" fw="300">
              {dayjs(record.dateCreated).format('DD MMM YYYY')}
            </Text>
            <StatusBadge status={recordWithStatus?.status ?? 'Unknown'} />
          </Group>
        </Group>
        <Group>
          <Popover opened={deleteDialogOpened} withArrow closeOnEscape closeOnClickOutside>
            <Popover.Target>
              <ActionIcon
                variant="white"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteDialogAction.open();
                }}
                size="lg"
              >
                <IconTrash style={{ margin: 'auto' }} />
              </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack gap={10}>
                คุณต้องการลบข้อมูลบันทึกนี้หรือไม่?
                <Group justify="right">
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(record.recordId);
                    }}
                  >
                    ลบ
                  </Button>
                  <Button onClick={deleteDialogAction.close} variant="outline">
                    ไม่ลบ
                  </Button>
                </Group>
              </Stack>
            </Popover.Dropdown>
          </Popover>
        </Group>
      </Flex>
    </Card>
  );
}
