import { createSlice } from "@reduxjs/toolkit"

export type PageWithProps = {
    page: string
    props: any
}

const routeSlice = createSlice({
    name: 'route',
    initialState: {
        route: 'Login',
        props: {},
    } as any,
    reducers: {
        switchTo(state: any, action: { payload: string | PageWithProps }) {
            state.route = typeof action.payload === 'string' ? action.payload : action.payload.page
            state.props = typeof action.payload === 'string' ? {} : action.payload.props
        }
    }
})

export default routeSlice.reducer
export const { switchTo } = routeSlice.actions