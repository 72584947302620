import { AppShell, Container } from '@mantine/core';
import { TopBar } from '../CommonWidget/TopBar';
import SideBar from '../CommonWidget/SideBar';
import { Notifications } from '@mantine/notifications';

export function PageWrapper(props: any) {
  const headerHeight = 64;
  return (
    <AppShell
      header={{ height: headerHeight }}
      navbar={{ width: 265, breakpoint: 'sm', collapsed: { desktop: false, mobile: true } }}
    >
      <AppShell.Header>
        <TopBar />
      </AppShell.Header>
      <AppShell.Navbar bg="#ffffff">
        <SideBar />
      </AppShell.Navbar>
      <AppShell.Main bg="#FFFFFF">
        <Container fluid size="xl" mx="lg" my="md" h={`calc(100vh - ${headerHeight + 48}px)`} pb={'48px'}>
          {props.children}
          <Notifications />
        </Container>
      </AppShell.Main>
    </AppShell>
  );
}
