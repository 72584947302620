import styled from 'styled-components'

export const Button = styled.button<{$wide?: boolean}>`
    font-family: Avenir;
    font-size: 14px;
    background: #EC1E35;
    color: white;
    padding: 14px;
    border-radius: 8px;
    border: 0px;
    cursor: pointer;
    ${ props => props.$wide && 'min-width: 344px;' }

    &:disabled {
        background: #C8C8D0;
        color: white;
    }
`
