import { ReactElement } from 'react'
import { autoFormat } from '../Utils/FormatUtil'
import styled from 'styled-components'

export type FieldDataType = "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function" | "string" | "number"

export interface FieldDefinition {
    /**
     * The key that identifies the field in the object in `items` array
     */
    key: string

    /**
     * The text to be displayed in the field's header, if not the `key`
     */
    label?: string

    /**
     * Type of data in the field. This will help the formatter to automatically
     * adjust the display of field values correctly; e.g. numbers are right-aligned, etc.
     */
    type?: FieldDataType

    /** width in CSS unit, e.g. 100px 20rem 30% */
    width?: string 

    /** 
     * Function that formats the arbitrary type of value to string. Default to `autoFormat`.
     * This option is ignored when `renderer` is given.
     */
    formatter?: (value: any) => string

    /**
     * The function returning JSX Element that renders each cell for this field.
     * @param props 
     * @returns 
     */
    renderer?: (props: any) => ReactElement

    sortable?: boolean
    searchable?: boolean
}

export interface TableProps {
    items: any[]
    fields?: FieldDefinition[]
    emptyMessage?: string
}

const TableContainer = styled.table`
    width: 100%;
`
const TableHeader = styled.thead``
const TableHeaderCell = styled.th`
    color: var(--brand-abacus-primary-purple, var(--brand-abacus-primary-purple, #4D2E83));
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding: 24px 10px;
    text-align: center;
`
const TableBody = styled.tbody``
const TableRow = styled.tr``
const EmptyRow = styled(TableRow)`
    width: 100%;
    & td {
        text-align: center;
        font-style: italic;
        padding: 32px;
        color: var(--fill-disabled, #C8C8D0);
    }
`

const TableCell = styled.td<{$datatype?: FieldDataType}>`
border-collapse: collapse;
    border-right: 1px solid var(--fill-illustration-bg, #DEDEE3);
    padding: 16px 8px;

    color: var(--fill-clickable-default, #5E5E6E);
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:last-child {
        border-right: none;
    }

    &.text-right { text-align: right; }
    &.text-center { text-align: center; }

    ${props => (props.$datatype === 'number' || typeof(props.children) === "number") ? 'text-align: right': 'text-align: left'}
`

export function Table(props: TableProps) {
    const _fields = props.fields ?? Object.keys(props.items[0] ?? {}).map(key => ({ key }) as FieldDefinition)
    
    const _formatItem = (value: any, f: FieldDefinition) => {
        if (f.renderer != null) {
            return <f.renderer {...{value}}/>
        } else if (f.formatter != null) {
            return f.formatter(value)
        } else {
            return autoFormat(value)
        }
    }

    return <TableContainer>
        <TableHeader>
            <TableRow>
                { _fields.map((field, i) => <TableHeaderCell key={i}>{ field.label ?? field.key }</TableHeaderCell>) }
            </TableRow>
        </TableHeader>

        <TableBody>
            { props.items.map((item, i) => 
                <TableRow key={i}>
                    { _fields.map((field, j) => <TableCell key={j} $datatype={field.type ?? typeof(item[field.key] as FieldDataType)}> {_formatItem(item[field.key], field)} </TableCell>) }
                </TableRow>) 
            }
            { props.items.length === 0 && <EmptyRow><td colSpan={1}>{props.emptyMessage ?? 'No Data'}</td></EmptyRow> }
        </TableBody>
    </TableContainer>
}