import styled from 'styled-components'
import { Group } from '@mantine/core'

export const HeaderWithButtons = styled(Group)`
    align-items: center;
    margin: 0 0 16px 0;

    & > * {
        display: flex;
        flex-grow: 0;
        align-items: center;
        justify-content: flex-end;
    }

    & > *:first-child {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
`