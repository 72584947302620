import styled from 'styled-components'

export const TextInput = styled.input.attrs({ type: 'text' })`
    flex-grow: 1;
    border-radius: 8px;
    padding: 12px;
    border: 1px solid var(--stroke-default);
    background: var(--fill-card-default, #FFF);
    font-family: 'Avenir';
    font-size: 16px;
    color: var(--fill-clickable-default)
`