import { Input, CloseButton, Image } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { listCustomer } from '../../../Features/CustomerSlice';
import { AppDispatch } from '../../../Store';

export default function CustomerSearchInput() {
  const dispatch = useDispatch<AppDispatch>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchString, setSearchString] = useDebouncedState('', 300);

  useEffect(() => {
    dispatch(listCustomer(searchString));
  }, [searchString]);

  const _handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const handleClearClick = () => {
    setSearchString('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <Input
      ref={inputRef}
      placeholder="ค้นหาจากชื่อ เลขประจำตัวประชาชน หรือ เบอร์โทรฯ"
      w="50%"
      fw="300"
      defaultValue={searchString}
      leftSection={<Image src="/assets/search.svg" w="20px" h="20px" />}
      rightSectionPointerEvents="all"
      rightSection={
        <CloseButton
          aria-label="Clear input"
          onClick={handleClearClick}
          style={{ display: searchString ? undefined : 'none' }}
        />
      }
      onChange={_handleChange}
    />
  );
}
