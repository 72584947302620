import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Record } from '../Models/Record';
import axios from 'axios';

const getRecord = async (params: { clientId?: string; recordId?: string }) => {
  const res = await axios.get<{ data: Record }>(`/api/internal/record/${params.clientId}/${params.recordId}`);
  return _responseToRecordMapper(res.data.data);
};

const myRecordSlice = createSlice({
  name: 'record',
  initialState: {} as any,
  reducers: {},
});

function _responseToRecordMapper(apires: any): Record | null {
  if (apires == null) return null;

  return {
    recordId: apires.recordId,
    clientId: apires.clientId,
    userId: apires.userId,
    name: apires.recordName,
    dateCreated: apires.dateCreated,
    status: apires.status,
    bankStatementList: apires.statements,
    result: apires.result,
    statusMessage: apires.statusMessage,
    error: apires.error,
    path: `/${apires.clientId}/${apires.recordId}/record.json`,
    dateSubmitted: apires.dateSubmitted,
  };
}

export default myRecordSlice.reducer;
export const {} = myRecordSlice.actions;
export { getRecord };
