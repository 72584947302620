import axios from 'axios';
import React, { useEffect } from 'react';
import { AppDispatch, persistor } from '../../Store';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../Features/UserSlice';

export default function SessionValidator({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const removeUser = async () => {
      await persistor.purge();
      dispatch(logout({}));
      navigate('/login');
    };

    const validateToken = async () => {
      try {
        const userData = sessionStorage.getItem('user');
        if (!userData) {
          await removeUser();
          return;
        }

        const parsedUserData = JSON.parse(userData);

        if (!parsedUserData.authToken) {
          return;
        }

        const { authToken } = parsedUserData;

        // Call the API to validate the token
        const response = await axios.post('/api/internal/auth/verify-token', {
          token: authToken,
        });

        const isValid = await response.data;

        if (!isValid) {
          await removeUser();
        }
      } catch (error: any) {
        await removeUser();
      }
    };

    if (['/update-password', '/forget-password'].some((path) => path === location.pathname)) {
      return;
    }
    validateToken();
  }, []);

  return <>{children}</>;
}
