import { Divider, Group, Space, Text, Stack } from '@mantine/core';
import { PageWrapper } from '../../../../Views/PageWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerInfoStaged } from '../../../../Models/CustomerInfo';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../Store';
import { useForm } from '@mantine/form';
import { createCustomer, editCustomer } from '../../../../Features/CustomerSlice';
import { useClientId, useTenantAlias } from '../../../../hooks/user';
import ButtonBar from './components/ButtonBar';
import FieldsGrid from './components/FieldsGrid';
import { FieldInfo, personalInfoFields, citizenIdFields, contactInfoFields, titleFields } from './other/fields';
import { AppBreadCrumbs } from '../../../../common/components/BreadCrumbs';
import { useEffect } from 'react';
import { useDebouncedCallback } from '@mantine/hooks';

interface FormValues {
  title?: string;
  firstname?: string;
  lastname?: string;
  cid?: string;
  laserId?: string;
  birthDate?: Date | null;
  mobilePhone?: string;
  email?: string;
}

const listValidators = (fieldInfo: FieldInfo[]) =>
  fieldInfo.reduce((acc, field) => ({ ...acc, [field.field]: field.validator }), {});

const removeHyphens = (input?: string): string | null => {
  if (!input) return null;
  return input.replace(/-/g, '');
};

export function CustomerEditorPage() {
  const activeCustomer = useSelector((state: RootState) => state.customerSlice.activeCustomer);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const clientId = useClientId();
  const alias = useTenantAlias();
  const { customerId } = useParams();

  const form = useForm<FormValues>({
    initialValues: {
      ...activeCustomer,
      birthDate: activeCustomer.birthDate ? new Date(activeCustomer.birthDate) : null,
    },
    validate: {
      ...listValidators(personalInfoFields),
      ...listValidators(citizenIdFields),
      ...listValidators(contactInfoFields),
    },
  });

  const _confirmCancel = () => {
    navigate(-1);
  };

  const _confirmSubmit = () => {
    form.onSubmit(async () => {
      const { cid, laserId } = form.values;
      const _data = {
        ...form.values,
        _id: activeCustomer._id,
        clientId: activeCustomer.clientId,
        cid: removeHyphens(cid),
        laserId: removeHyphens(laserId),
      };

      const filteredData = Object.fromEntries(Object.entries(_data).filter(([_, value]) => value != null));

      if (customerId === 'new') {
        const response: any = await dispatch(createCustomer(filteredData as unknown as CustomerInfoStaged));
        navigate(`/${alias}/customer/${response.payload._id}/data`);
      } else {
        dispatch(editCustomer(filteredData as unknown as CustomerInfoStaged));
        navigate(-1);
      }
    })();
  };

  const breadCrumbItems = [
    {
      label: 'ข้อมูลลูกค้า',
      href: `/${alias}/customer/`,
    },
    {
      label: `${activeCustomer?.creating ? 'สร้าง' : 'แก้ไข'}ข้อมูลลูกค้า`,
      href: '',
    },
  ];

  const validateFields = useDebouncedCallback(async () => {
    const fields = Object.keys(form.values);
    for (const field of fields) {
      if (form.isDirty(field)) {
        form.validateField(field);
      }
    }
  }, 300);

  useEffect(() => {
    validateFields();
  }, [form.values]);

  if (!customerId || !clientId) return;

  return (
    <PageWrapper>
      <Group mb="22px">
        <AppBreadCrumbs items={breadCrumbItems} />
      </Group>

      <Group justify="space-between" mb="32px">
        <Text size="lg" fw="500" c="#26262C">
          ข้อมูลส่วนตัว
        </Text>
      </Group>

      <Stack w="70%">
        <Stack mb="32px">
          <FieldsGrid fields={titleFields} form={form}></FieldsGrid>
          <FieldsGrid fields={personalInfoFields} form={form}></FieldsGrid>
        </Stack>

        <Stack mb="32px">
          <Group justify="space-between">
            <Text size="lg" fw="500" c="#26262C">
              ข้อมูลบัตรประจำตัวประชาชน
            </Text>
          </Group>
          <FieldsGrid fields={citizenIdFields} form={form}></FieldsGrid>
        </Stack>

        <Stack mb="32px">
          <Group justify="space-between">
            <Text size="lg" fw="500" c="#26262C">
              ข้อมูลการติดต่อ
            </Text>
          </Group>
          <FieldsGrid fields={contactInfoFields} form={form}></FieldsGrid>
        </Stack>
      </Stack>

      <Divider />
      <Space h="lg" />
      <ButtonBar
        customerId={activeCustomer._id!}
        clientId={clientId!}
        onCancel={_confirmCancel}
        onSubmit={_confirmSubmit}
      />
      <Space h="lg" />
    </PageWrapper>
  );
}
