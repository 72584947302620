import { Box, Button, Center, Group, Space, Stack, Title } from '@mantine/core';
import { PageWrapper } from '../../Views/PageWrapper';
import { Pagination } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { clearActiveCustomer, listCustomer } from '../../Features/CustomerSlice';
import { listRecords } from '../../Features/MyRecordsSlice';
import { CustomerTable } from './components/CustomerTable';
import { useClientId, useTenantAlias } from '../../hooks/user';
import CustomerSearchInput from './components/CustomerSearchInput';

export function CustomerManagementPage() {
  const navigate = useNavigate();
  const customers = useSelector((state: RootState) => state.customerSlice.customerList);
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(1);
  const perPage = 20;
  const clientId = useClientId();
  const alias = useTenantAlias();

  const customersOnPage = (customers ?? []).filter((_, i) => i >= (page - 1) * perPage && i <= page * perPage);

  useEffect(() => {
    dispatch(listCustomer('')).then(() => {
      const list = customersOnPage
        ?.map((c) => c.records?.map((rec) => rec.recordId))
        .flat()
        .filter((e) => e !== undefined);
      dispatch(listRecords({ list }));
    });
  }, [page]);

  const onCreateCustomer = () => {
    dispatch(clearActiveCustomer());
    // dispatch(createCustomer());
    navigate(`/${alias}/customer/new/edit`);
  };

  return (
    <PageWrapper>
      <Stack h="100%">
        <Title order={3} c={'#4D2E83'}>
          ข้อมูลลูกค้า
        </Title>
        <Group justify="space-between">
          <CustomerSearchInput />
          <Button onClick={onCreateCustomer}>กรอกข้อมูลลูกค้า</Button>
        </Group>
        <Box style={{ flexGrow: 1 }}>
          <CustomerTable data={(customers ?? []).filter((_, i) => i >= (page - 1) * perPage && i < page * perPage)} />
        </Box>
        <Center>
          <Pagination value={page} onChange={setPage} total={Math.ceil(customers.length / perPage)}></Pagination>
        </Center>
        <Space h="lg" />
      </Stack>
    </PageWrapper>
  );
}
