import { Modal, Stack, TextInput, Button } from '@mantine/core';
import { useForm, isNotEmpty } from '@mantine/form';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addCustomerRecord } from '../../../../../Features/CustomerSlice';
import { addMyRecord, createRecord } from '../../../../../Features/MyRecordsSlice';
import { AppDispatch, RootState } from '../../../../../Store';

export default function CreateNewRecordModal(props: {
  createDialogOpen: boolean;
  createRecordAction: { open: () => void; close: () => void };
}) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const activeUser = useSelector((state: RootState) => state.userSlice.activeUser);
  const activeCustomer = useSelector((state: RootState) => state.customerSlice.activeCustomer);
  const form = useForm({ initialValues: { recordName: '' }, validate: { recordName: isNotEmpty('ใส่ชื่อบันทึก') } });

  useEffect(() => {
    form.reset();
  }, [props.createDialogOpen]);

  const handleRecordSubmit = form.onSubmit((values) => {
    dispatch(
      addMyRecord({
        recordName: values.recordName,
        clientId: activeUser?.tenant.clientId!,
        userId: activeUser?.userId!,
      })
    );
    dispatch(createRecord({ recordName: values.recordName, customerId: activeCustomer._id! })).then((action: any) => {
      const recordId = action.payload.data.data.recordId;
      dispatch(
        addCustomerRecord({
          customerId: activeCustomer._id!,
          recordId,
          name: values.recordName,
          path: `/${activeUser?.tenant.clientId}/${recordId}`,
        })
      );
      navigate(`/${activeUser?.tenant.alias}/upload-statement/?customerId=${activeCustomer._id}`);
    });
    props.createRecordAction.close();
  });

  return (
    <Modal
      opened={props.createDialogOpen}
      onClose={() => props.createRecordAction.close()}
      title="สร้างบันทึกเอกสารใหม่"
    >
      <form onSubmit={handleRecordSubmit}>
        <Stack>
          <TextInput label="ชื่อบันทึก" placeholder="ชื่อบันทึก" {...form.getInputProps('recordName')} />
          <Button fullWidth type="submit">
            สร้างบันทึก
          </Button>
        </Stack>
      </form>
    </Modal>
  );
}
