import { Button, Group, Table, Image } from '@mantine/core';
import { Record as UserRecord, RecordResult } from '../../../../../../Models/Record';
import { exportCSV } from '../../../../../../Utils/export';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CustomerInfoStaged } from '../../../../../../Models/CustomerInfo';
import { mask } from '../../../../../../Utils/mask';

dayjs.extend(utc);
dayjs.extend(timezone);

interface DataObject {
  [key: string]: any;
}

export default function SummarySection({ record, customer }: { record: UserRecord; customer: CustomerInfoStaged }) {
  const data = record.result;
  if (!data) return;

  const toSummary = (data: RecordResult): DataObject => {
    return removeUndefinedProperties({
      'Income Analyzer': data['Income Analyzer'],
      'Cash Flow Analyzer': data['Cash Flow Analyzer'],
      'ABACUS Statement Prediction': data['ABACUS Statement Prediction'],
      'Advanced: Daily Balance Statistics': data['Advanced: Daily Balance Statistics'],
      'Advanced: Weekly Average Transactions': data['Advanced: Weekly Average Transactions'],
      'Advanced: Credit to Debit Ratios': data['Advanced: Credit to Debit Ratios'],
      'Advanced: Transaction Volumes by Types': data['Advanced: Transaction Volumes by Types'],
      'Advanced Parameters': data['Advanced Parameters'],
    });
  };

  function removeUndefinedProperties<T extends Record<string, any>>(obj: T): T {
    for (const key in obj) {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    }
    return obj;
  }

  const convertToCSV = (data: DataObject, completedDate: string, customer: CustomerInfoStaged) => {
    let csvContent = `Customer name,${customer.firstname} ${customer.lastname}\nEmail, ${customer.email}\nID,\t${
      customer.cid
    }\nJob completed date,"${dayjs(completedDate).tz('Asia/Bangkok').format('YYYY-MMM-DD HH:mm:ss')}"\n,\n`;

    for (const [sectionName, sectionData] of Object.entries(data)) {
      csvContent += `${sectionName}\n`;

      if (typeof sectionData === 'object') {
        for (const [key, value] of Object.entries(sectionData)) {
          const formattedValue = `"${value}"`;
          csvContent += `${key},${formattedValue}\n`;
        }
      } else {
        csvContent += `${sectionData}\n\n`;
      }
    }
    const csvData = '\uFEFF' + csvContent;
    return csvData;
  };

  const summary = toSummary(data);

  const handleExportClick = async () => {
    const csvData = convertToCSV(summary, record.dateSubmitted, customer);
    await exportCSV(
      csvData,
      `summary_${mask(customer.cid || '', '999XXXXXX9999', 'X')}_${dayjs(record.dateSubmitted)
        .tz('Asia/Bangkok')
        .format('YYYYMMDD')}`
    );
  };

  return (
    <section style={{ marginTop: '32px' }}>
      <Group justify="end">
        {Object.keys(summary).length === 0 || (
          <Button
            variant="transparent"
            leftSection={<Image src="/assets/share.svg" alt="export" />}
            onClick={handleExportClick}
          >
            Export csv
          </Button>
        )}
      </Group>
      <Group w="65%">
        {Object.keys(summary).map((key, i) => {
          const details = summary[key];
          return <SummaryDetail key={`${key}_${i}`} label={key} data={details} />;
        })}
      </Group>
    </section>
  );
}

function SummaryDetail({ data, label }: { data: DataObject; label: string }) {
  if (!data) return <></>;
  return (
    <Table withRowBorders={false} mb="32px">
      <Table.Thead>
        <Table.Tr fz="xl" fw={500}>
          <Table.Th c="purple.6">{label}</Table.Th>
          <Table.Th></Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {Object.keys(data).map((key, i) => (
          <Table.Tr fz="lg" key={`${key}_${i}`}>
            <Table.Td w="70%">{key}</Table.Td>
            <Table.Td ta="right" fw={500} c="#26262C">
              {data[key]}
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
}
