import {
  Button,
  Container,
  Stack,
  Title,
  Image,
  Text,
  Modal,
  LoadingOverlay,
  PasswordInput,
  Box,
  Flex,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetPassword, verifyLink } from '../../api/user';
import { useEffect, useState } from 'react';

export default function UpdatePasswordPage() {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [expired, setExpired] = useState<boolean>(false);
  const [search] = useSearchParams();
  const token = search.get('token');

  useEffect(() => {
    const verfiy = async () => {
      const [err, email] = await verifyLink(token);
      if (err || !email) {
        setExpired(true);
        return;
      }
      setEmail(email);
    };
    verfiy();
  }, [token]);

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },

    validate: {
      confirmPassword: (value, values) => (value !== values.password ? 'รหัสผ่านไม่ตรงกัน' : null),
    },
  });

  const handleSubmit = async (values: typeof form.values) => {
    setLoading(true);
    await resetPassword(email, values.confirmPassword);
    setLoading(false);
    open();
  };

  return (
    <Container fluid h="100vh" p="xl">
      <LoadingOverlay visible={isLoading} zIndex={1000} />
      <Stack p="16px">
        {expired ? (
          <ExpiredLink />
        ) : (
          <section style={{ marginTop: '15%' }}>
            <Stack w="28%" m="auto">
              <Title ta="center" fz="24px" mb="24px" c="#26262C">
                ตั้งรหัสผ่านใหม่
              </Title>
              <form onSubmit={form.onSubmit(handleSubmit)} style={{ display: 'flex', flexDirection: 'column' }}>
                <Text>อีเมล</Text>
                <Text mb="24px" fw="500">
                  {email}
                </Text>
                <PasswordInput size="lg" mb="24px" label="รหัสผ่านใหม่" {...form.getInputProps('password')} />
                <PasswordInput
                  size="lg"
                  mb="24px"
                  label="ยืนยันรหัสผ่านใหม่"
                  {...form.getInputProps('confirmPassword')}
                />
                <Button size="lg" type="submit">
                  ยืนยัน
                </Button>
              </form>
            </Stack>
          </section>
        )}
      </Stack>
      <Modal opened={opened} withCloseButton={false} onClose={close} centered size="xs">
        <Modal.Header>
          <Image src="/assets/check-circle.svg" ml="auto" mr="auto" />
        </Modal.Header>
        <Modal.Body>
          <Stack justify="center">
            <Text fw={500} fz="16px" c={'#26262C'} ta="center">
              เปลี่ยนรหัสผ่านเรียบร้อย
            </Text>
            <Button size="lg" onClick={() => navigate('/login')}>
              ตกลง
            </Button>
          </Stack>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

function ExpiredLink() {
  const navigate = useNavigate();
  return (
    <Flex m="15% auto 0 auto" justify="center" direction="column" w="30%">
      <Title fz={'24px'} ta="center" mb="16px">
        ลิงก์นี้หมดอายุแล้ว
      </Title>
      <Text ta="center" mb="16px">
        ทำรายการอีกครั้งเพื่อตั้งรหัสผ่านใหม่
      </Text>
      <Button size="lg" onClick={() => navigate('/login')}>
        กลับไปหน้าเข้าสู่ระบบ
      </Button>
    </Flex>
  );
}
