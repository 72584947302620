import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CustomerInfo, CustomerInfoStaged } from '../Models/CustomerInfo';
import axios from 'axios';
import { RootState } from '../Store';
import { notifications } from '@mantine/notifications';

function getUser(thunkApi: any): { userId: string; tenant: { clientId: string } } {
  const state = thunkApi.getState() as RootState;
  const activeUser = state.userSlice.activeUser;
  if (activeUser == null) throw new Error('Invalid User. Please login again.');
  return activeUser;
}

const createCustomer = createAsyncThunk(
  'customer/create',
  async (cust: CustomerInfoStaged, thunkApi: any): Promise<{ _id: string; clientId: string; creating: boolean }> => {
    const clientId = getUser(thunkApi)?.tenant.clientId;
    const ret = await axios.post(`/api/internal/customer/${clientId}/create`, cust);
    return { _id: ret.data.data, clientId: clientId!, creating: true };
  }
);

const editCustomer = createAsyncThunk('customer/edit', async (cust: CustomerInfoStaged, thunkApi: any) => {
  const clientId = getUser(thunkApi)?.tenant.clientId;
  if (cust._id == null) throw new Error('Invalid customer ID');
  const ret = await axios.post(`/api/internal/customer/${clientId}/${cust._id}/info`, cust);
  return ret.data;
});

const listCustomer = createAsyncThunk('customer/list', async (searchString: string, thunkApi: any) => {
  const state = thunkApi.getState() as RootState;
  const clientId = state.userSlice.activeUser?.tenant.clientId;
  const ret = await axios.get(`/api/internal/customer/${clientId}?s=${encodeURIComponent(searchString)}`);
  return ret.data;
});

const deleteCustomer = createAsyncThunk('customer/delete', async (customerId: string, thunkApi: any) => {
  const clientId = getUser(thunkApi)?.tenant.clientId;
  const ret = await axios.delete(`/api/internal/customer/${clientId}/${customerId}`);
  return ret.data;
});

const loadActiveCustomer = createAsyncThunk('customer/loadActive', async (customerId: string, thunkApi: any) => {
  const clientId = getUser(thunkApi)?.tenant.clientId;
  const ret = await axios.get(`/api/internal/customer/${clientId}/${customerId}/info`);
  return ret.data;
});

const addCustomerRecord = createAsyncThunk(
  'customer/addRecord',
  async (arg: { customerId: string; recordId: string; name: string; path: string }, thunkApi: any) => {
    const user = getUser(thunkApi);
    const ret = await axios.post(`/api/internal/customer/${user.tenant.clientId}/${arg.customerId}/record`, {
      recordId: arg.recordId,
      name: arg.name,
      path: arg.path,
      userId: arg.customerId,
    });
    return ret.data;
  }
);

const newCustomerTemplate: CustomerInfoStaged = {
  _id: '',
  clientId: '',
  creating: true,
};

const customerSlice = createSlice({
  name: 'userSlice',
  initialState: {
    customerList: [] as CustomerInfo[],
    activeCustomer: Object.assign(newCustomerTemplate) as CustomerInfoStaged,
    error: null as string | null,
    loading: false,
  },
  reducers: {
    setActiveCustomer: (state, action: { payload: CustomerInfo }) => {
      state.activeCustomer = { ...action.payload, creating: false };
    },

    clearActiveCustomer(state) {
      state.activeCustomer = Object.assign(newCustomerTemplate);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createCustomer.fulfilled, (state, action) => {
      try {
        state.activeCustomer = action.payload;
      } catch (e) {
        state.error = (e as Error).message;
        console.log(e);
      }
    });

    builder.addCase(loadActiveCustomer.fulfilled, (state, action) => {
      state.activeCustomer = action.payload.data;
    });

    builder.addCase(listCustomer.fulfilled, (state, action) => {
      state.customerList = action.payload.data;
    });

    builder.addCase(listCustomer.rejected, (state, action) => {
      state.error = 'Failed to list customers';
    });

    builder.addCase(createCustomer.rejected, (state, action) => {
      state.error = 'Failed to create a customer';
    });

    builder.addCase(editCustomer.fulfilled, (state, action) => {});

    builder.addCase(editCustomer.rejected, (state, action) => {
      state.error = 'Failed to save a customer information';
    });

    builder.addCase(addCustomerRecord.fulfilled, (state, action) => {
      if (state.activeCustomer.records == null) state.activeCustomer.records = [];

      state.activeCustomer.records.push({
        recordId: action.meta.arg.recordId,
        name: action.meta.arg.name,
        path: action.meta.arg.path,
        status: 'Draft',
        dateCreated: new Date(),
      });
    });

    builder.addCase(addCustomerRecord.rejected, (state, action) => {
      state.error = 'Failed to add a statement';
    });

    builder.addCase(deleteCustomer.fulfilled, (state, action) => {
      notifications.show({ title: 'ลบรายชื่อลูกค้า', message: `ลบรายชื่อลูกค้าเรียบร้อยแล้ว`, color: 'green' });
    });

    builder.addCase(deleteCustomer.rejected, (state, action) => {
      notifications.show({
        title: 'พบปัญหา',
        message: `พบปัญหาระหว่างการลบรายชื่อลูกค้า ${action.meta.arg}`,
        color: 'red',
      });
    });
  },
});

export default customerSlice.reducer;
export const { setActiveCustomer, clearActiveCustomer } = customerSlice.actions;
export { createCustomer, editCustomer, deleteCustomer, listCustomer, loadActiveCustomer, addCustomerRecord };
