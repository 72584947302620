import { Button, Divider, Group, Stack, TextInput, Text, Flex, Modal, Image, Fieldset } from '@mantine/core';
import { AppBreadCrumbs } from '../../../common/components/BreadCrumbs';
import { useClientId, User, useTenantAlias } from '../../../hooks/user';
import { PageWrapper } from '../../../Views/PageWrapper';
import { useForm } from '@mantine/form';
import { useNavigate, useParams } from 'react-router-dom';
import { createUser, getUser } from '../../../api/user';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
// @ts-ignore
import classes from './user-detail.module.css';

export function UserDetailPage({ isEdit = false }: { isEdit?: boolean }) {
  const navigate = useNavigate();
  const alias = useTenantAlias();
  const clientId = useClientId();
  const [opened, { open, close }] = useDisclosure(false);
  const { id: userId } = useParams();
  const [curUser, setCurUser] = useState<User | undefined>();

  const breadCrumbItems = [
    {
      label: 'จัดการผู้ใช้งาน',
      href: `/${alias}/users`,
    },
    {
      label: isEdit ? `${curUser?.firstname || '-'} ${curUser?.lastname || ''}` : `เพิ่มผู้ใช้งาน`,
      href: '',
    },
  ];

  useEffect(() => {
    if (isEdit && userId && userId !== 'new' && clientId) {
      const loadUser = async (id: string) => {
        const [err, user] = await getUser(clientId, userId);
        setCurUser(user);
        if (user) {
          form.setValues({ ...user, email: user.userId });
        }
      };
      loadUser(userId);
    }
  }, [userId]);

  const form = useForm({
    initialValues: {
      firstname: curUser?.firstname || '',
      lastname: curUser?.lastname || '',
      email: curUser?.userId || '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'อีเมลไม่ถูกต้อง'),
      firstname: (value) => !value,
      lastname: (value) => !value,
    },
  });

  const handleSubmit = async (values: typeof form.values) => {
    if (!clientId) return;

    const [err, result] = await createUser(clientId, values);
    if (err) {
      notifications.show({
        title: 'พบปัญหา',
        message: `พบปัญหาระหว่างการสร้างผู้ใช้งาน โปรดลองใหม่ในภายหลัง`,
        color: 'red',
      });
      return;
    }
    open();
  };

  const handleModalClose = () => {
    close();
    navigate(`/${alias}/users`);
  };

  return (
    <PageWrapper>
      <form onSubmit={form.onSubmit(handleSubmit)} style={{ height: '100%' }}>
        <Flex direction="column" h="100%">
          <Group mb="22px">
            <AppBreadCrumbs items={breadCrumbItems} />
          </Group>
          <Fieldset disabled={isEdit} bd="none" style={{ boxShadow: '0px 8px 16px 0px rgba(222, 222, 227, 0.4)' }}>
            <Stack mt="24px">
              <Stack mb="16px">
                <Text size="lg" fw="500" c="#26262C">
                  ข้อมูลส่วนตัว
                </Text>
                <Group align="flex-start">
                  <TextInput
                    label={'ชื่อ'}
                    w="30%"
                    {...form.getInputProps('firstname')}
                    withAsterisk={!isEdit}
                    classNames={classes}
                  />
                  <TextInput
                    label={'นามสกุล'}
                    w="30%"
                    {...form.getInputProps('lastname')}
                    withAsterisk={!isEdit}
                    classNames={classes}
                  />
                </Group>
              </Stack>
              {isEdit && <Divider />}
              <Stack mt="16px">
                <Text size="lg" fw="500" c="#26262C">
                  ข้อมูลติดต่อ
                </Text>
                <Group align="flex-start">
                  <TextInput
                    label={'อีเมลติดต่อ'}
                    w="30%"
                    {...form.getInputProps('email')}
                    withAsterisk={!isEdit}
                    classNames={classes}
                  />
                </Group>
              </Stack>
            </Stack>
          </Fieldset>
          {!isEdit && (
            <Stack mt="auto">
              <Divider mb="32px" />
              <Group justify="space-between">
                <Button variant="white" c="red">
                  ลบข้อมูล
                </Button>
                <Group w="40%">
                  <Button variant="outline" onClick={() => navigate(-1)} w="45%">
                    ยกเลิก
                  </Button>
                  <Button type="submit" w="45%">
                    ส่งอีเมล
                  </Button>
                </Group>
              </Group>
            </Stack>
          )}
        </Flex>
      </form>

      <Modal opened={opened} withCloseButton={false} onClose={close} centered size="xs">
        <Modal.Header>
          <Image src="/assets/check-circle.svg" ml="auto" mr="auto" />
        </Modal.Header>
        <Modal.Body>
          <Stack justify="center">
            <Text fw={500} fz="16px" c={'#26262C'} ta="center">
              ส่งข้อมูลให้ผู้ใช้งานเรียบร้อย
            </Text>
            <Button onClick={handleModalClose}>กลับไปหน้าหลัก</Button>
          </Stack>
        </Modal.Body>
      </Modal>
    </PageWrapper>
  );
}
