import { Text } from '@mantine/core'
import styled from 'styled-components'

export const NormalText = styled(Text)`
    color: var(--text-default, #26262C);
    font-family: 'Kanit';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px; /* 137.5% */
`
export const SubHeadingText = styled(Text)`
    font-family: 'Kanit', sans-serif;
    color: var(--text-default, #26262C);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`

export const HeadingText = styled(Text)`
    color: var(--text-default, #26262C);
    font-family: 'Kanit', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 122.222% */
`