import styled from 'styled-components'

export const PageContent = styled.div`
    width: 864px;
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 64px;

    @media (max-width: 500px) {
        width: 100%;
        overflow: hidden;
        margin-left: 1em;
        margin-right: 1em;
        box-sizing: border-box;
    }
`