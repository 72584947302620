import { TextInput, Select, InputBase, SimpleGrid, Image } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import { useMemo } from 'react';
import InputMask from 'react-input-mask';
import { FieldInfo } from '../other/fields';

export default function FieldsGrid({ fields, form }: { fields: FieldInfo[]; form: UseFormReturnType<any, any> }) {
  const renderField = useMemo(() => {
    return (field: FieldInfo, index: number) => {
      const commonProps = {
        label: field.label,
        ...form.getInputProps(field.field),
        size: 'lg',
        fw: '300',
        styles: {
          label: {
            fontWeight: 300,
            fontSize: '16px',
            marginBottom: '8px',
            color: '#5E5E6E',
            textWrap: 'nowrap',
          },
        },
        withAsterisk: field.withAsterisk,
      };

      switch (field.type) {
        case 'text':
          return <TextInput key={`${index}_${field.label}`} {...commonProps} maxLength={field.maxLength} />;
        case 'dropdown':
          return (
            <Select
              key={`${index}_${field.label}`}
              data={field.options?.map((opt) => ({ label: opt.label, value: opt.value }))}
              {...commonProps}
            />
          );
        case 'date':
          return (
            <DatePickerInput
              key={`${index}_${field.label}`}
              {...commonProps}
              size="lg"
              rightSectionPointerEvents="none"
              rightSection={<Image src="/assets/calendar.svg" />}
            />
          );
        case 'cid':
          return (
            <InputBase
              key={`${index}_${field.label}`}
              component={InputMask}
              mask="9-9999-99999-99-9"
              maskChar={null}
              {...commonProps}
            />
          );
        case 'laserId':
          return (
            <InputBase
              key={`${index}_${field.label}`}
              component={InputMask}
              mask="aa9-9999999-99"
              maskChar={null}
              {...commonProps}
            />
          );
        default:
          return null;
      }
    };
  }, [form]);

  return (
    <>
      <SimpleGrid cols={2} spacing="sm" verticalSpacing="xs">
        {fields.map((field, index) => renderField(field, index))}
      </SimpleGrid>
    </>
  );
}
