import { isEmail } from '@mantine/form';
import { CustomerInfoStaged } from '../../../../../Models/CustomerInfo';
import {
  emailValidator,
  isOptional,
  isRequired,
  isThaiId,
  isThailandPhoneNumber,
} from '../../../../../Utils/validators';

interface TitleOption {
  label: string;
  value: string;
}

export interface FieldInfo {
  label: string;
  type: string;
  field: keyof CustomerInfoStaged;
  validator?: (arg: any) => any;
  options?: TitleOption[];
  withAsterisk?: boolean;
  maxLength?: number;
}

export const titleFields: FieldInfo[] = [
  {
    label: 'คำนำหน้า',
    type: 'dropdown',
    options: [
      { label: 'นาย', value: 'mr' },
      { label: 'นาง', value: 'mrs' },
      { label: 'นางสาว', value: 'ms' },
    ],
    field: 'title',
  },
];
export const personalInfoFields: FieldInfo[] = [
  {
    label: 'ชื่อ',
    type: 'text',
    field: 'firstname',
    withAsterisk: true,
    validator: isRequired('กรุณาใส่ชื่อ'),
  },
  { label: 'นามสกุล', type: 'text', field: 'lastname', withAsterisk: true, validator: isRequired('กรุณาใส่นามสกุล') },
  // { label: 'ชื่อจริงภาษาไทย', type: 'text', field: 'firstnameTh', validator: isOptional(isThai()) },
  // { label: 'นามสกุลภาษาไทย', type: 'text', field: 'lastnameTh', validator: isOptional(isThai()) },
  // { label: 'ชื่อจริงภาษาอังกฤษ', type: 'text', field: 'firstnameEn', validator: isOptional(isEnglish()) },
  // { label: 'นามสกุลภาษาอังกฤษ', type: 'text', field: 'lastnameEn', validator: isOptional(isEnglish()) },
];

export const citizenIdFields: FieldInfo[] = [
  {
    label: 'เลขประจำตัวประชาชน (13 หลัก)',
    type: 'cid',
    field: 'cid',
    validator: isThaiId(),
    withAsterisk: true,
  },
  // {
  //   label: 'รหัส Laser ID หลังบัตรประจำตัวประชาชน (12 หลัก)',
  //   type: 'laserId',
  //   field: 'laserId',
  //   validator: isOptional(isLaserId()),
  // },
  {
    label: 'วันเกิดบนบัตรประชาชน',
    type: 'date',
    field: 'birthDate',
    validator: isOptional(),
  },
];

export const contactInfoFields: FieldInfo[] = [
  {
    label: 'เบอร์โทรศัพท์มือถือ',
    type: 'text',
    field: 'mobilePhone',
    validator: isThailandPhoneNumber(),
    maxLength: 13,
  },
  {
    label: 'อีเมลติดต่อ',
    type: 'text',
    field: 'email',
    validator: emailValidator(),
    withAsterisk: true,
    maxLength: 100,
  },
];
