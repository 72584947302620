import { Record } from '../Models/Record';
import { Grid, Paper, Text } from '@mantine/core';

type ScoreType = 'string' | 'number' | 'integer' | 'boolean' | 'date';
interface ScoreToShow {
  label: string;
  dataType: ScoreType;
  jsonPath: string;
}

const defaultScoresToShow = [
  { label: 'Income Stability', dataType: 'string', jsonPath: 'result/Income Analyzer/Predicted Income Stability' },
  { label: 'Monthly Income', dataType: 'number', jsonPath: 'result/Income Analyzer/Predicted Monthly Income' },
  {
    label: 'Monthly Debt Burden',
    dataType: 'number',
    jsonPath: 'result/Income Analyzer/Predicted Monthly Debt Burden',
  },
  {
    label: 'Abacus Risk Grade',
    dataType: 'string',
    jsonPath: 'result/ABACUS Statement Prediction/ABACUS Statement Transaction Fraud Risk',
  },
  {
    label: 'Abacus Risk Score',
    dataType: 'string',
    jsonPath: 'result/ABACUS Statement Prediction/ABACUS Statement Risk Score',
  },
  {
    label: 'Avg. 4-wk Daily Balance',
    dataType: 'string',
    jsonPath: 'result/Advanced: Daily Balance Statistics/Daily Balance Average Last 4 Weeks',
  },
];

export function ScoreGrid({ record, scoresToShow }: { record: Record | undefined; scoresToShow?: ScoreToShow[] }) {
  const _toShow = scoresToShow ?? defaultScoresToShow;
  return (
    <Grid p="16px 24px">
      {_toShow.map((score) => (
        <Grid.Col span={3} key={'col_' + score.label}>
          <ScoreCard
            key={score.label}
            label={score.label}
            value={formatValueByType(getValueByJsonPath(record, score.jsonPath), score.dataType as ScoreType)}
          />
        </Grid.Col>
      ))}
    </Grid>
  );
}

export function ScoreCard({ label, value }: { label?: string; value?: string }) {
  return (
    <Paper p="sm" style={{ border: '1px solid rgba(222, 222, 227, 1)' }}>
      <Text fw={300}>{label ?? ''}</Text>
      <Text fw={500}>{value ?? '-'}</Text>
    </Paper>
  );
}

function formatValueByType(value: any, type: 'string' | 'number' | 'integer' | 'boolean' | 'date'): string {
  return String(value); // for now.
}

function getValueByJsonPath<T>(obj: object | undefined, jsonPath: string): T {
  if (obj == null) return ' - ' as unknown as T;
  const path = jsonPath.split('/');
  let value: any = obj;
  for (const elem of path) {
    value = value?.[elem];
    if (value == null) return ' - ' as unknown as T;
  }
  return value as T;
}
